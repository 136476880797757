// import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
// import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button, FormControl, InputLabel, Grid, Select, MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function PlanEdit(props) {
    const [btnLoad, setbtnLoad] = useState(false);
    const [mType, setType] = useState('');
    const [plan, setPlanData] = useState({
        url: '',
        token: '',
        type: ''
    })
    useEffect(() => {
        setPlanData({
            url: props.id.url,
            token: props.id.token,
            type: props.id.type
        });
        setType(props.id.type);
    }, [props.id]);


    const formik = useFormik({
        initialValues: plan,
        enableReinitialize: true,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            initialValues.type = mType
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            console.log(initialValues);
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp/qr/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    // if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    //     toast.success('Updated Sucessfully')
                    //     setbtnLoad(false)
                    //     props.setdataShow(props.prevState.map(item => (
                    //         item._id === props.id._id ? { ...data } : item
                    //     )))
                    //     props.popupChange(false);
                    // } else {
                    //     setbtnLoad(false)
                    //     toast.error(data.message)
                    // }
                });
        }
    });

    const { handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    const handleChange = (e) => {
        setType(e.target.value);
        // setPlanData((prev)=>({...prev,"type":e.target.value}))
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="url"
                                type="text"
                                variant="standard"
                                label="URL"
                                {...getFieldProps('url')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="token"
                                type="text"
                                variant="standard"
                                label="Token"
                                {...getFieldProps('token')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={mType}
                                    onChange={handleChange}
                                    label="Age"
                                >
                                    <MenuItem value="affiliatersmd">Affiliatersmd</MenuItem>
                                    <MenuItem value="chat-api">Chat API</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>

                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
