import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';
// import Autocomplete from '@mui/material/Autocomplete';
import {
    Stack,
    TextField,
    Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';
// ----------------------------------------------------------------------

export default function ForwarderLogin(props) {
    // console.log(props)
    const [btnLoad, setbtnLoad] = useState(false);

    const LoginSchema = Yup.object().shape({
        phone: Yup.string().min(12, 'Too Short!').max(12, 'Too Long!').required('Mobile required include 91'),
    });
    const formik = useFormik({
        initialValues: {
            phone: '',
        },
        enableReinitialize: true,
        validationSchema: LoginSchema,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues),
            };
            console.log(initialValues);


            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/forwarder/login", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setbtnLoad(false)
                    console.log(data);

                    //   {status: 'success', value: 'Already Logged In, please use apiKey '}
                    //   {"status":"success","timeout":"The code will be expired in 180 seconds","value":"The OTP has been sent successfully"}
                    if (Object.prototype.hasOwnProperty.call(data, "status")) {
                        setbtnLoad(false)
                        if (data.value === "The OTP has been sent successfully") {
                            props.popupChange(false);
                            props.openOtpPopup(true);
                            toast.success(data.value);
                        } else if (data.value.includes("Already Logged In")) {
                            props.popupChange(false);
                            toast.success(data.value);
                        } else {
                            props.popupChange(false);
                            toast.warning(data.value);
                        }
                    } else {
                        props.popupChange(false);
                        setbtnLoad(false)
                        toast.error(data.message);
                    }
                });
        }
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="phone"
                        type="text"
                        variant="standard"
                        label="Mobile"
                        {...getFieldProps('phone')}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                    />
                </Stack>
                <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
