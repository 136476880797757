import React from 'react'
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import  HeaderForm  from './HeaderForm'

// ----------------------------------------------------------------------


export default function Footer() {
    return (
        <Page title="Header">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Add your Header
                    </Typography>
                </Stack>
                <HeaderForm />
            </Container>
        </Page>
    );
}
