import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';

const options = ['Active', 'Deactive'];

export default function UserAddonSearch(props) {
    const [email, setEmail] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [user, setUser] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [plan, setPlan] = React.useState('');
    const [btnLoad, setbtnLoad] = React.useState(false);
    const [btnResetLoad, setResetLoad] = React.useState(false);
    const arr = [];
    props.totalData.forEach((arrayItem) => (
        arr.push(arrayItem.user.username)
    ));
    const newArr = [...new Set(arr)];

    const mobileArr = [];
    props.totalData.forEach((arrayItem) => (
        mobileArr.push(arrayItem.user.mobile)
    ));
    const newMobileArr = [...new Set(mobileArr)];

    const emailArr = [];
    props.totalData.forEach((arrayItem) => (
        emailArr.push(arrayItem.user.email)
    ));
    const newEmailArr = [...new Set(emailArr)];

    const planArr = [];
    props.totalData.forEach((arrayItem) => (
        planArr.push(arrayItem.plan.name)
    ));
    const newPlanArr = [...new Set(planArr)];

    const allData = props.totalData;
    let filterdata = [];
    const handleResetClick = () => {
        setResetLoad(true)
        props.setdataShow(allData)
        setEmail('');
        setStatus('');
        setUser('');
        setMobile('');
        setPlan('');
        if (allData.length > 15) {
            props.setlimit(5)
            props.seterror(false)
        } else if (allData.length <= 15) {
            props.setlimit(5)
            props.seterror(true)
        } else {
            props.setlimit(0)
            props.seterror(true)
        }
        setResetLoad(false)
    }
    const handleClick = () => {
        setbtnLoad(true)
        // user search-------------------------------------------------
        if (user) {
            const result = allData.filter((item) => (
                item.user.username === user
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        } else {
            filterdata = (filterdata.length === 0) ? allData : filterdata
        }
        // token search 
        
        if (mobile && filterdata) {
            const result = filterdata.filter((item) => (
                item.user.mobile === mobile
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        } 
        if (email && filterdata) {
            const result = filterdata.filter((item) => (
                item.user.email === email
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        } 

        if (plan && filterdata) {
            const result = filterdata.filter((item) => (
                item.plan.name === plan
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        }
        // status search --------------------------------------------------------
        if (status && filterdata) {
            const st = (status === 'Active' ? 'true' : 'false') === 'true'
            const result = filterdata.filter((item) => (
                item.status === st
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        }
        props.setdataShow(filterdata)
        if (filterdata.length > 15) {
            props.setlimit(5)
            props.seterror(false)
        } else if (filterdata.length <= 15) {
            props.setlimit(5)
            props.seterror(true)
        } else {
            props.setlimit(0)
            props.seterror(true)
        }
        setbtnLoad(false)
    }
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} md={6}> <Autocomplete
                    value={user}
                    onChange={(event, newValue) => {
                        setUser(newValue);
                    }}
                    id="controllable-states-demo"
                    options={newArr}
                    getOptionLabel={(option) => option || ""}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} variant="standard" label="User Search" />}
                /> </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={mobile}
                        onChange={(event, newValue) => {
                            setMobile(newValue);
                        }}
                        id="controllable-states-demo"
                        options={newMobileArr}
                        getOptionLabel={(option) => option || ""}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Mobile Search" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={email}
                        onChange={(event, newValue) => {
                            setEmail(newValue);
                        }}
                        id="controllable-states-demo"
                        options={newEmailArr}
                        getOptionLabel={(option) => option || ""}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Email Search" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={plan}
                        onChange={(event, newValue) => {
                            setPlan(newValue);
                        }}
                        id="controllable-states-demo"
                        options={newPlanArr}
                        getOptionLabel={(option) => option || ""}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Plan Search" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={status}
                        onChange={(event, newValue) => {
                            setStatus(newValue);
                        }}
                        id="status"
                        options={options}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Status Search" />}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                        onClick={handleClick}
                    >
                        Search
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        color="error"
                        loading={btnResetLoad}
                        onClick={handleResetClick}
                    >
                        Reset
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}
