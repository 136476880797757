import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';
const options = ['Active', 'Deactive'];
export default function ForwarderSearch(props) {
    console.log(props)
    const [name, setName] = React.useState('');
    const [username, setUserName] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [btnLoad, setbtnLoad] = React.useState(false);
    const [btnResetLoad, setResetLoad] = React.useState(false);
    const nameArr = [];
    props.totalData.forEach((arrayItem) => (
        // console.log(arrayItem.name)
        nameArr.push(arrayItem.name)
    ));
    const newNameArr = [...new Set(nameArr)];

    const userNameArr = [];
    props.totalData.forEach((arrayItem) => (
        userNameArr.push(arrayItem.username)
    ));
    const newUserNameArr = [...new Set(userNameArr)];

    // console.log(newNameArr)

    const allData = props.totalData;
    let filterdata = [];
    const handleResetClick = () => {
        setResetLoad(true)
        props.setdataShow(allData)
       setStatus('')
       setName('');
       setUserName('');
        if (allData.length > 15) {
            props.setlimit(5)
            props.seterror(false)
        } else if (allData.length <= 15) {
            props.setlimit(5)
            props.seterror(true)
        } else {
            props.setlimit(0)
            props.seterror(true)
        }
        setResetLoad(false)
    }
    const handleClick = () => {
        setbtnLoad(true)
        // user search-------------------------------------------------
        if (name) {
            const result = allData.filter((item) => (
                item.name === name
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        } else {
            filterdata = (filterdata.length === 0) ? allData : filterdata
        }
        // token search 

        if (username && filterdata) {
            const result = filterdata.filter((item) => (
                item.username === username
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        }else{
            filterdata = (filterdata.length === 0) ? allData : filterdata
        }

        if (status && filterdata) {
            const st = (status === 'Active' ? 'true' : 'false') === 'true'
            const result = filterdata.filter((item) => (
                item.status === st
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        }

        props.setdataShow(filterdata)
        if (filterdata.length > 15) {
            props.setlimit(5)
            props.seterror(false)
        } else if (filterdata.length <= 15) {
            props.setlimit(5)
            props.seterror(true)
        } else {
            props.setlimit(0)
            props.seterror(true)
        }
        setbtnLoad(false)
    }
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} md={6}> <Autocomplete
                    value={name}
                    onChange={(event, newValue) => {
                        setName(newValue);
                    }}
                    id="controllable-states-demo"
                    options={newNameArr}
                    getOptionLabel={(option) => option || ""}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Name Search" />}
                /> </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={username}
                        onChange={(event, newValue) => {
                            setUserName(newValue);
                        }}
                        id="controllable-states-demo"
                        options={newUserNameArr}
                        getOptionLabel={(option) => option || ""}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="UserName Search" />}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={status}
                        onChange={(event, newValue) => {
                            setStatus(newValue);
                        }}
                        id="status"
                        options={options}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Status Search" />}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                        onClick={handleClick}
                    >
                        Search
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        color="error"
                        loading={btnResetLoad}
                        onClick={handleResetClick}
                    >
                        Reset
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}
