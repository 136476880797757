import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Grid, Card,Typography,Switch,FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HelpVideo } from '../../components/authentication/help';
import LoadingScreen from '../../components/LoadingScreen';
// ----------------------------------------------------------------------

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function AffilateForm() {
    const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    const [data, setData] = useState({
        amazon: '',
        flipkart: '',
        earnkaro_link: '',
        cuelink: '',
        inr_deals: '',
        amazon_convert:'',
        flipkart_convert:'',
        other_convert:'',
        earnkaro:'' // earnkaro ID 
    });
    const [token, setToken] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [amazonConvert, setAmazonConvert] = useState({
        amazon: true,
        earnkaro: false,
        cuelinks: false,
        inr_deals: false,
    });
    const [flipkartConvert, setFlipkartConvert] = useState({
        flipkart: true,
        earnkaro: false,
        cuelinks: false,
        inr_deals: false,
    });
    const [otherConvert, setOtherConvert] = useState({
        earnkaro: true,
        cuelinks: false,
        inr_deals: false,
    });

    const repeatTasks = (array,mapItem)=>{
        const newObj = {};
        Object.keys(array).map((item) => (
            mapItem === item ?
                newObj[item] = true : newObj[item] = false
        ));
        return newObj
    }

    const updateConverter=(arr)=>{
        const filteredObj = Object.keys(arr).reduce((p, c) => {    
            if (arr[c]) p[c] = arr[c];
            return p;
          }, {});


        return (Object.keys(filteredObj)).toString()
    }
    const handleAmazonRadio = (event) => {
        setAmazonConvert(repeatTasks(amazonConvert,event.target.name))
        // setData(prev => ({...prev,'amazon_convert':event.target.name}))
    };
    const handleFlipkartRadio = (event) => {
        setFlipkartConvert(repeatTasks(flipkartConvert,event.target.name))
        // setData(prev => ({...prev,'flipkart_convert':event.target.name}))
    };
    const handleOtherRadio = (event) => {
        setOtherConvert(repeatTasks(otherConvert,event.target.name))
        // setData(prev => ({...prev,'other_convert':event.target.name}))
    };

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);

        if (bundle != null) {
            setToken(bundle)
        } else {
            alert("no token found");
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/affiliate", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    setData({
                        amazon:data.amazon,
                        flipkart:data.flipkart,
                        earnkaro_link:data.earnkaro_link,
                        cuelink:data.cuelink,
                        inr_deals:data.inr_deals,
                        amazon_convert:data.amazon_convert,
                        flipkart_convert:data.flipkart_convert,
                        other_convert:data.other_convert,
                        earnkaro:data.earnkaro, // earnkaro ID 
                    })
                    setAmazonConvert(repeatTasks(amazonConvert,data.amazon_convert))
                    setFlipkartConvert(repeatTasks(flipkartConvert,data.flipkart_convert))
                    setOtherConvert(repeatTasks(otherConvert,data.other_convert))
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, []);

    const RegisterSchema = Yup.object().shape({
        amazon: Yup.string().required('Amazon Tag ID is required'),
        flipkart: Yup.string().required('Flipkart Aff ID is required'),
        earnkaro_link: Yup.string().required('EarnKaro Referral Link is required'),
        cuelink: Yup.string().required('Cuelink ID is required'),
        inr_deals: Yup.string().required('INR Deals Username is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: RegisterSchema,
        onSubmit: (initialValues) => {
            setbtnLoad(true)            
              initialValues.amazon_convert = updateConverter(amazonConvert);
              initialValues.flipkart_convert = updateConverter(flipkartConvert);
              initialValues.other_convert = updateConverter(otherConvert);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(initialValues),
            };
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/affiliate", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        setbtnLoad(false)
                        setData({
                            amazon:data.amazon,
                            flipkart:data.flipkart,
                            earnkaro_link:data.earnkaro_link,
                            cuelink:data.cuelink,
                            inr_deals:data.inr_deals,
                            amazon_convert:data.amazon_convert,
                            flipkart_convert:data.flipkart_convert,
                            other_convert:data.other_convert,
                            earnkaro:data.earnkaro, // earnkaro ID 
                        })
                        setAmazonConvert(repeatTasks(amazonConvert,data.amazon_convert))
                        setFlipkartConvert(repeatTasks(flipkartConvert,data.flipkart_convert))
                        setOtherConvert(repeatTasks(otherConvert,data.other_convert))
                        toast.success('Saved Sucessfully');
                    } else {
                        setbtnLoad(false)
                        toast.error(`${data.message}`);
                    }
                });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    {isLoading ? <LoadingScreen sx={{
                        top: 40,
                        left: 0,
                        width: 1,
                        zIndex: 9999,
                        position: 'fixed'
                    }} />
                        :
                        <Card sx={{ p: 3 }}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Amazon Tag ID"
                                            value={values.amazon}
                                            {...getFieldProps('amazon')}
                                            error={Boolean(touched.amazon && errors.amazon)}
                                            helperText={touched.amazon && errors.amazon}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={values.flipkart}
                                            label="FlipKart Tag ID"
                                            {...getFieldProps('flipkart')}
                                            error={Boolean(touched.flipkart && errors.flipkart)}
                                            helperText={touched.flipkart && errors.flipkart}
                                        />
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="EarnKaro Referral Link"
                                            value={values.earnkaro_link}
                                            {...getFieldProps('earnkaro_link')}
                                            error={Boolean(touched.earnkaro_link && errors.earnkaro_link)}
                                            helperText={touched.earnkaro_link && errors.earnkaro_link}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={values.cuelink}
                                            label="Cuelink cid (find cid in full link converted from cuelink)"
                                            {...getFieldProps('cuelink')}
                                            error={Boolean(touched.cuelink && errors.cuelink)}
                                            helperText={touched.cuelink && errors.cuelink}
                                        />
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="INR Deal Username"
                                            value={values.inr_deals}
                                            {...getFieldProps('inr_deals')}
                                            error={Boolean(touched.inr_deals && errors.inr_deals)}
                                            helperText={touched.inr_deals && errors.inr_deals}
                                        />
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} >
                                        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                            <Grid item xs={12} md={6} sm={6} >
                                                <Grid container spacing={1} sx={{ p: 2, border: '1px solid grey', borderRadius: '5px' }}>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <Typography variant="h6">Convert Amazon links with</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12}>
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('amazon')} checked={amazonConvert.amazon} onChange={handleAmazonRadio} />}
                                                            label="Default"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('earnkaro')} checked={amazonConvert.earnkaro} onChange={handleAmazonRadio} />}
                                                            label="Earnkaro"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12}>
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('cuelinks')} checked={amazonConvert.cuelinks} onChange={handleAmazonRadio} />}
                                                            label="Cuelink"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12}>
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('inr_deals')} checked={amazonConvert.inr_deals} onChange={handleAmazonRadio} />}
                                                            label="INR Deal"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={6} sm={6} >
                                                <Grid container spacing={1} sx={{ p: 2, border: '1px solid grey', borderRadius: '5px' }}>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <Typography variant="h6">Convert Flipkart links with</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12}  >
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('flipkart')} checked={flipkartConvert.flipkart} onChange={handleFlipkartRadio} />}
                                                            label="Default"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12} sm={12} >
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('earnkaro')} checked={flipkartConvert.earnkaro} onChange={handleFlipkartRadio} />}
                                                            label="Earnkaro"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12} >
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('cuelinks')} checked={flipkartConvert.cuelinks} onChange={handleFlipkartRadio} />}
                                                            label="Cuelink"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12}>
                                                        <FormControlLabel
                                                            control={<Android12Switch {...getFieldProps('inr_deals')} checked={flipkartConvert.inr_deals} onChange={handleFlipkartRadio} />}
                                                            label="INR Deal"
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12} sm={12} >
                                                <Grid container spacing={1} sx={{ p: 2, border: '1px solid grey', borderRadius: '5px' }}>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <Typography variant="h6">Convert Others links with</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={8} sm={12}  >
                                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                                <FormControlLabel
                                                                    control={<Android12Switch {...getFieldProps('earnkaro')} checked={otherConvert.earnkaro} onChange={handleOtherRadio} />}
                                                                    label="Earnkaro"
                                                                    labelPlacement="end"
                                                                />
                                                            </Stack>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                                <FormControlLabel
                                                                    control={<Android12Switch {...getFieldProps('cuelinks')} checked={otherConvert.cuelinks} onChange={handleOtherRadio} />}
                                                                    label="Cuelink"
                                                                    labelPlacement="end"
                                                                />
                                                            </Stack>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                                <FormControlLabel
                                                                    control={<Android12Switch {...getFieldProps('inr_deals')} checked={otherConvert.inr_deals} onChange={handleOtherRadio} />}
                                                                    label="INR Deal"
                                                                    labelPlacement="end"
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <LoadingButton
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            loading={btnLoad}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Stack>
                                </Stack>

                            </Form>
                        </Card>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <HelpVideo title="Header" url="https://www.youtube.com/embed/JbDktrsnH40" />
                </Grid>
            </Grid>
        </FormikProvider >
    );
}
