// import useFetch from '../_mocks_/useFetch';
// const { apiData } = useFetch();
// console.log(apiData);
import CryptoJS from "crypto-js";

const bundle = localStorage.getItem("aln_bundle")

const account ={
    displayName: '',
    email: '',
    photoURL: '/static/mock-images/avatars/avatar_default.jpg',
    pass_key:null,
    mobile:''
};

if (bundle != null) {
    const bytes = CryptoJS.AES.decrypt(bundle, 'secret key 123');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log("bundleeeeeeeeee",decryptedData)
    account.displayName = decryptedData.username;
    account.pass_key = decryptedData.admin;
    account.email = decryptedData.email;
    account.mobile = decryptedData.mobile;
}else{
    account.displayName= 'abc';
    account.email= 'abc@minimals.cc';
    account.pass_key = 0;
    account.mobile='';
}

// console.log(account)

export default account;