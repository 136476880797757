import { useState, useEffect } from 'react';
// import { toast } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack,
  TextField,
  Button, Autocomplete, Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function ForwarderAdd(props) {
  const [btnLoad, setbtnLoad] = useState(false);
  const [value, setValue] = useState('');
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.accessToken}`
      },
    };
    fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data)
      });
  }, []);
  const formik = useFormik({
    initialValues: {
      user_id: value._id,
      url: '',
    },
    enableReinitialize: true,
    onSubmit: (initialValues) => {
      setbtnLoad(true)
      console.log(initialValues)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues),
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/forwarder/setup", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          //   if (Object.prototype.hasOwnProperty.call(data, "_id")) {
          //     setbtnLoad(false)
          //     props.popupChange(false);
          //     toast.success('Saved Sucessfully');
          //     window.location.reload();
          //   } else {
          //     setbtnLoad(false)
          //     toast.error(data.message);
          //   }
        });
    }
  });
  // console.log(type);

  const { handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };


  // console.log(type)
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack>
          <Autocomplete mt={2}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            id="controllable-states-demo"
            options={userData}
            getOptionLabel={(option) => option.username || ""}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.username}
              </Box>
            )}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} variant="standard" label="User Name" />}
          />
          <TextField sx={{ mt: "10px" }}
            fullWidth
            autoComplete="url"
            type="text"
            variant="standard"
            label="URL"
            {...getFieldProps('url')}
          />

        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Save
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider >
  );
}
