// material
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Typography, Button} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

// import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import  Video  from './Video';

// ----------------------------------------------------------------------

const ContentStyle = styled(Card)(({ theme }) => ({
    marginTop: -120,
    boxShadow: 'none',
    padding: theme.spacing(5),
    paddingTop: theme.spacing(16),
    color: theme.palette.common.white,
    backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`
}));
export default function Popup(props) {
    const { title, url} = props;
    const [open, setOpen] = useState(false);
    const setOpenPopup = () => {
      setOpen((add) => !add);
    };
    const handleClickOpen = () => {
      setOpen((add) => !add);
    }
    return (
        <>
        <Box
            component="img"
            src="/static/illustrations/illustration_invite.png"
            sx={{
                zIndex: 9,
                position: 'relative',
                left: 40,
                width: 140,
                filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))'
            }}
        />
        <ContentStyle>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">
                    Help Video for {title}
                </Typography>
            </Stack>

            <Typography variant="body2" sx={{ mt: 2, mb: 3 }}>
            <Button variant="contained"
                startIcon={<YouTubeIcon />}
                color="warning"
                size="medium"
                onClick={handleClickOpen}
                >PLAY NOW
            </Button>
            </Typography>
            
        </ContentStyle>
            
            <Video title={title} openPopup={open} setOpenPopup={setOpenPopup} url={url} />
          </>
        )
    }
