import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Switch, Grid, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Card, Button, Container, Stack, Typography, Paper, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
// import editFill from '@iconify/icons-eva/edit-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import Page from '../../../components/Page';
// import Popup from '../../../components/Popup';
// import PlanEdit from './PlanEdit';
// import PlanAdd from './PlanAdd';
import UserAddonSearch from './UserAddonSearch';
import 'react-toastify/dist/ReactToastify.css';
import Label from '../../../components/Label';
import LoadingScreen from '../../../components/LoadingScreen';


// ----------------------------------------------------------------------
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        // color: '#fafafa',
        // borderRadius: '2px',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// ----------------------------------------------------------------------

export default function UserAddon() {
    const navigate = useNavigate();
    // const [openEdit, setOpenEdit] = useState(false);
    // const [open, setOpen] = useState(false);
    const [dataShow, setdataShow] = useState([]);
    // const [id, setID] = useState(null);
    const [accessToken, setAccessToken] = useState();
    const [totalData, setTotalData] = useState([]);
    const [limit, setLimit] = useState(15);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);
        if (bundle != null) {
            setAccessToken(bundle);
        } else {
            toast.error('No Token Found');
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/plans/admin/addons", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                console.log(data)
                if (data.length !== undefined) {
                    setdataShow(data);
                    setTotalData(data);
                    setLoading(false);
                    if (limit >= dataShow.length) {
                        setError(true);
                    }
                } else {
                    toast.error(data.message);
                }
            });
    }, []);

    const handleDelete = (id) => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        };
        fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/plans/admin/addons/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "success")) {
                    toast.error('Deleted Sucessfully');
                    const newArray = dataShow.filter((item) => (
                        item._id !== id
                    ));
                    setdataShow(newArray);
                } else {
                    toast.error(data.message);
                }
            });
    }


    const handleRadioChange = (e, id) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ "id": id, [e.target.name]: e.target.checked })
        };
        fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/plans/admin/addons`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    setdataShow(dataShow.map(item => (
                        item._id === id ? { ...data } : item
                    )))
                    toast.success("Update Successfully");
                } else {
                    toast.error(data.message);
                }
            });
    }
    const handleLoadMore = () => {
        if (limit < dataShow.length) {
            setLimit(limit + 5);
            const l = limit + 5;
            if (l >= dataShow.length) {
                setError(true);
            }
        } else {
            setError(true);
        }

    }

    return (
        <Page title="User Plan">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        User Plan
                    </Typography>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 0, mb: 3 }}>
                            <Accordion sx={{ p: 0, m: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Filters
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
                                        <UserAddonSearch prevState={dataShow} totalData={totalData} setdataShow={setdataShow} setlimit={setLimit} seterror={setError} />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Card>
                        {isLoading ? <LoadingScreen sx={{
                            top: 40,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed'
                        }} /> :
                            <Card sx={{ p: 3 }}>
                                {(dataShow.length > 0 && dataShow !== undefined) ? dataShow.slice(0, limit).map((item) => (
                                    <Paper
                                        key={item._id}
                                        sx={{
                                            p: 3,
                                            width: 1,
                                            marginBottom: 1,
                                            bgcolor: 'background.neutral'
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        User Name: &nbsp;
                                                    </Typography>
                                                    {item.user.username}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Email: &nbsp;
                                                    </Typography>
                                                    {item.user.email}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Mobile: &nbsp;
                                                    </Typography>
                                                    {item.user.mobile}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Plan Name: &nbsp;
                                                    </Typography>
                                                    {item.plan.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom>
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Amount: &nbsp;
                                                    </Typography>
                                                    {item.plan.amount}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Typography variant="body2" gutterBottom>
                                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                Status: &nbsp;
                                            </Typography>
                                            <Label
                                                color={item.status === false ? 'error' : 'success'}
                                                sx={{ textTransform: 'uppercase' }}
                                            >
                                                {item.status === false ? 'Deactive' : 'Active'}
                                            </Label> &nbsp;

                                        </Typography>
                                        <Box sx={{ mt: 1 }}>
                                            <FormControlLabel
                                                control={<Android12Switch name="status" value={item.status} checked={item.status} onChange={(e) => handleRadioChange(e, item._id)} />}
                                                label=""
                                            />
                                            {/* <Button size="small" startIcon={<Icon icon={editFill} />} onClick={() => handleEditOpen(item)}>
												Edit
											</Button> */}
                                            <Button
                                                color="error"
                                                size="small"
                                                startIcon={<Icon icon={trash2Fill} />}
                                                onClick={() => handleDelete(item._id)}
                                                sx={{ mr: 1 }}
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    </Paper>
                                )) : <Grid item xs={12} sm={12} md={12} >
                                    <Typography variant="h" gutterBottom>
                                        No data found
                                    </Typography>
                                </Grid>}
                                <div style={{ textAlign: 'center' }}>
                                    {error ? '' : <Button varient="container" color="primary" onClick={handleLoadMore}>Load More</Button>}
                                </div>
                            </Card>}

                    </Grid>

                </Grid>

            </Container>
            {/* <Popup title="Add Plan" openPopup={open} setOpenPopup={setOpenPopup}>
				<PlanAdd popup={open} prevState={dataShow} setdataShow={setdataShow} id={id} accessToken={accessToken} popupChange={handleClickClose} />
			</Popup>
			<Popup title="Edit Plan" openPopup={openEdit} setOpenPopup={setEditPopup}>
				<PlanEdit popup={openEdit} prevState={dataShow} setdataShow={setdataShow} id={id} accessToken={accessToken} popupChange={handleEditClose} />
			</Popup> */}
        </Page>
    );
}
