import React from 'react'
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import  AmazonAPIForm  from './AmazonAPIForm';

// ----------------------------------------------------------------------


export default function AmazonApiSetting() {
    return (
        <Page title="Amazon API Setting">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Amazon API Setting
                    </Typography>
                </Stack>
                <AmazonAPIForm />
            </Container>
        </Page>
    );
}
