
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, Card, Button, Container, Stack, Typography, Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Page from '../../../components/Page';
import BloggerSearch from './BloggerSearch';
import 'react-toastify/dist/ReactToastify.css';
import Label from '../../../components/Label';
import LoadingScreen from '../../../components/LoadingScreen';


// ----------------------------------------------------------------------

export default function Blogger() {
    const navigate = useNavigate();
    const [dataShow, setdataShow] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [limit, setLimit] = useState(15);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);
        if (bundle != null) {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${bundle}`
                },
            };
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blogger", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    setLoading(false)
                    if (data.data.length !== undefined) {
                        setdataShow(data.data);
                        setTotalData(data.data);
                        setLoading(false);
                        if (limit >= data.data.length) {
                            setError(true);
                        }
                    } else {
                        toast.error(data.message);
                    }
                });
        } else {
            toast.error('No Token Found');
            navigate('/login', { replace: true });
        }

    }, []);


    const handleLoadMore = () => {
        if (limit < dataShow.length) {
            setLimit(limit + 5);
            const l = limit + 5;
            if (l >= dataShow.length) {
                setError(true);
            }
        } else {
            setError(true);
        }
    }
    return (
        <Page title="Bitly">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Blogger
                    </Typography>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 0, mb: 3 }}>
                            <Accordion sx={{ p: 0, m: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Filters
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
                                        <BloggerSearch prevState={dataShow} totalData={totalData} setdataShow={setdataShow} setlimit={setLimit} seterror={setError} />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Card>
                        {isLoading ? <LoadingScreen sx={{
                            top: 40,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed'
                        }} /> :
                            <Card sx={{ p: 3 }}>
                                {(dataShow.length > 0 && dataShow !== undefined) ? dataShow.slice(0, limit).map((item) => (
                                    <Paper
                                        key={item._id}
                                        sx={{
                                            p: 3,
                                            width: 1,
                                            marginBottom: 1,
                                            bgcolor: 'background.neutral'
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Name: &nbsp;
                                                    </Typography>
                                                    {item.user.username}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Email: &nbsp;
                                                    </Typography>
                                                    {item.user.email}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Mobile: &nbsp;
                                                    </Typography>
                                                    {item.user.mobile}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Blog ID: &nbsp;
                                                    </Typography>
                                                    {item.blog_id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography variant="body2" gutterBottom >
                                                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                        Blog URL: &nbsp;
                                                    </Typography>
                                                    {item.url}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="body2" gutterBottom>
                                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                Status: &nbsp;
                                            </Typography>
                                            <Label
                                                color={item.status === false ? 'error' : 'success'}
                                                sx={{ textTransform: 'uppercase' }}
                                            >
                                                {item.status === false ? 'Deactive' : 'Active'}
                                            </Label>
                                        </Typography>
                                    </Paper>
                                )) : <Grid item xs={12} sm={12} md={12} p={2}>
                                    <Typography variant="h4" display="block" gutterBottom>
                                        No data found
                                    </Typography>
                                </Grid>}
                                <div style={{ textAlign: 'center' }}>
                                    {error ? '' : <Button varient="container" color="primary" onClick={handleLoadMore}>Load More</Button>}
                                </div>
                            </Card>}

                    </Grid>

                </Grid>
            </Container>
        </Page>

    );
}
