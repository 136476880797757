import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';
// import Autocomplete from '@mui/material/Autocomplete';
import {
  Stack,
  TextField,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';
// ----------------------------------------------------------------------

export default function ForwarderLogin(props) {
  const [btnLoad, setbtnLoad] = useState(false);
  // const [value, setValue] = useState('');
  // const [userData, setUserData] = useState([]);


  const LoginSchema = Yup.object().shape({
    otp: Yup.string().required('Otp required'),
  });
  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (initialValues) => {
      setbtnLoad(true)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues),
      };
      console.log(initialValues);
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/forwarder/otp", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setbtnLoad(false)
          console.log(data);
          // props.popupChange(false);
          // props.openOtpPopup(true);
          // {"_id":"6204014bd731c9eeb5a3dcb9","user":"61ea848cd2140468ae59caf9","__v":0,"createdAt":"2022-02-09T18:00:43.670Z","name":"Diksha None","token":"uDubgNb1JaWYlWoOvMUIww","updatedAt":"2022-02-10T10:02:53.471Z","url":"https://forwarderhdhsr.herokuapp.com","username":null,"phone":"919463505811"}
          if (Object.prototype.hasOwnProperty.call(data, "success")) {
            props.popupChange(false);
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
        });
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack mt={2}>
          <TextField
            fullWidth
            autoComplete="otp"
            type="text"
            variant="standard"
            label="Otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Save
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
