import React, { useRef, useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
// import LoggedIn from '../../images/whatsapp-login.png';

export default function WhatsAppLogin(props) {
    const [qrState, setQrState] = useState({});
    const [loading, setLoading] = useState(false);
    const [showData, setShowData] = useState('');
    const dialogBox = useRef()
    const { accessToken, popup, popupChange } = props;

    const fetchQR = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp/login", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setLoading(false);
                setQrState(data);
                if (Object.prototype.hasOwnProperty.call(data, "status")) {
                    if (data.status === 1) {
                        setShowData('https://app.affiliaters.in/img/self/done.gif')
                    } else {
                        setShowData(data.qr)
                    }
                } else {
                    setShowData("https://app.affiliaters.in/img/self/error.gif")
                }
            });
    }
    useEffect(() => {
        setLoading(true);
        let interval = 0;
        if (popup === true) {
            fetchQR()
            if (qrState.message === 'Scan QR') {
                interval = setInterval(() => fetchQR(), 5000)
            }
        }

        return () => {
            clearInterval(interval);
        }
    }, [popup, qrState.message])

    console.log(showData);
    return (
        <Dialog
            ref={dialogBox}
            open={popup}
            maxWidth='md'
            onClose={() => { popupChange(false) }}
        >
            <DialogTitle style={{ display: 'flex', margin: 0 }}>
                <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                    WhatsApp Login QR
                </Typography>
                <CloseIcon color="error" onClick={() => { popupChange(false) }} />
            </DialogTitle>
            {qrState && <Typography>{qrState.message}</Typography>}
            {!loading && showData !== '' ? <img src={showData} alt="login QR" height="300" /> : <img src="https://media.giphy.com/media/TvLuZ00OIADoQ/giphy.gif" alt="Logged In" height="300" />}
        </Dialog>
    )
}