import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
// material
import { Stack, TextField, IconButton, InputAdornment, FormControlLabel, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function OwnShortnerForm() {
    const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({
        username: '',
        password: '',
        url: '',
        amazon_link: false,
        flipkart_link: false,
        other_link: false
    });
    const [token, setToken] = useState('');
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        // console.log(bundle);
        if (bundle != null) {
            setToken(bundle)
        } else {
            alert("no token found");
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/ownshortner", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    setData({
                        username: data.username,
                        password: data.password,
                        url: data.url,
                        amazon_link: data.amazon_link,
                        flipkart_link: data.flipkart_link,
                        other_link: data.other_link
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                    alert(data.message);
                }
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, []);

    // console.log("dataaaaaaa", data)

    const RegisterSchema = Yup.object().shape({
        url: Yup.string().required('URL is required'),
        username: Yup.string().required('Username required'),
        password: Yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: RegisterSchema,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(initialValues),
            };
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/ownshortner", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        setbtnLoad(false)
                        toast.success('Saved Sucessfully');
                    } else {
                        setbtnLoad(false)
                        toast.error(`${data.message}`);
                    }
                });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;


    return (
        <>
            {isLoading ? <LoadingScreen sx={{
                top: 40,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed'
            }} />
                :
                <Card sx={{ p: 3 }}>
                    <FormikProvider value={formik}>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Url where your script hosted"
                                    value={values.url}
                                    {...getFieldProps('url')}
                                    error={Boolean(touched.url && errors.url)}
                                    helperText={touched.url && errors.url}
                                />
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>


                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        value={values.username}
                                        label="Admin Username"
                                        {...getFieldProps('username')}
                                        error={Boolean(touched.username && errors.username)}
                                        helperText={touched.username && errors.username}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        label="Admin Password"
                                        {...getFieldProps('password')}
                                        value={values.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                                    <FormControlLabel style={{ margin: 0 }}
                                        control={<Android12Switch {...getFieldProps('amazon_link')} checked={values.amazon_link} />}
                                        label="Amazon Links in your shortner"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Android12Switch {...getFieldProps('flipkart_link')} checked={values.flipkart_link} />}
                                        label="Flipkart Links in your shortner"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Android12Switch {...getFieldProps('other_link')} checked={values.other_link} />}
                                        label="Others Links in your shortner"
                                        labelPlacement="end"
                                    />
                                </Stack>


                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={btnLoad}
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Card>
            }
        </>
    );
}
