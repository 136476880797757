
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, Card, Container, Stack, Typography, TextField, Chip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import BlacklistSearch from './BlacklistSearch';
import 'react-toastify/dist/ReactToastify.css';
import { HelpVideo } from '../../components/authentication/help';
import LoadingScreen from '../../components/LoadingScreen';


// ----------------------------------------------------------------------

export default function BlackList() {
    const navigate = useNavigate();
    const [dataShow, setdataShow] = useState([]);
    const [accessToken, setAccessToken] = useState();
    const [totalData, setTotalData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [word, setWord] = useState('');
    const [btnLoad, setbtnLoad] = useState(false);

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);
        if (bundle != null) {
            setAccessToken(bundle);
        } else {
            toast.error('No Token Found');
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blacklist", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                if (data.length !== undefined) {
                    setdataShow(data)
                    setTotalData(data)
                } else {
                    toast.error('Empty List');
                }
            });
    }, []);

    const handleDelete = (e, id) => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        };
        fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blacklist/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "success")) {
                    toast.error('Deleted Sucessfully');
                    const newArray = dataShow.filter((item) => (
                        item._id !== id
                    ));
                    setdataShow(newArray);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const handleAddWord = (e) => {
        setWord(e.target.value);
    }

    const handleAddWordClick = () => {
        if (word) {
            setbtnLoad(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify({ word }),
            };

            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blacklist", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        setdataShow([...totalData, data]);
                        toast.success('Add Sucessfully');
                        setbtnLoad(false)
                        setWord('');
                    } else {
                        toast.error(data.message);
                        setbtnLoad(false)
                    }
                })
        } else {
            toast.error("pehle likh to sahi");
        }
    }

    return (
        <Page title="Blacklist">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Blacklist
                    </Typography>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ p: 0, mb: 1 }}>
                            <Accordion sx={{ p: 0, m: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" >
                                        Filters
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
                                        <BlacklistSearch prevState={dataShow} totalData={totalData} setdataShow={setdataShow} />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Card>
                        <Card sx={{ p: 0, mb: 1 }}>
                            <Accordion sx={{ p: 0, m: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" >
                                        Add Keyword
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField fullWidth label="Keyword" variant="standard" value={word} onChange={(e) => handleAddWord(e)}
                                    />
                                    <Grid item xs={12} md={12} style={{ textAlign: 'right', marginTop: '10px' }}>
                                        <LoadingButton
                                            type="submit"
                                            variant="text"
                                            loading={btnLoad}
                                            onClick={handleAddWordClick}
                                        >
                                            Add
                                        </LoadingButton>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Card>

                        {isLoading ? <LoadingScreen sx={{
                            top: 40,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed'
                        }} /> :
                            <Card sx={{ p: 2 }}>
                                {(dataShow.length > 0 && dataShow !== undefined) ? dataShow.map((item, i) => (
                                    <Chip
                                        key={i}
                                        label={item.word}
                                        onDelete={(e) => handleDelete(e, item._id)}
                                        deleteIcon={<DeleteIcon style={{ color: "red" }} />}
                                        variant="outlined"
                                        style={{ margin: "2px" }}
                                        size="medium"
                                    />
                                )) : <Grid item xs={12} sm={12} md={12} p={2}>
                                    <Typography variant="h4" display="block" gutterBottom>
                                        No data found
                                    </Typography>
                                </Grid>}
                            </Card>}

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HelpVideo title="Blacklist" />
                    </Grid>

                </Grid>
            </Container>
        </Page>

    );
}
