import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack,
  TextField,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function WhishlistLogin(props) {
  const [btnLoad, setbtnLoad] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const [state, setState] = useState({
    token: '',
    chat_id: '',
    welcome_text: ''
  })

  const LoginSchema = Yup.object().shape({
    token: Yup.string().required('Bot Token is required'),
    chat_id: Yup.string().required('Admin Chat Id is required'),
    welcome_text: Yup.string().required('Welcome text is required'),
  });

  useEffect(() => {
    if (props.configData.length > 0) {
      setState({
        token: props.configData[0].token,
        chat_id: props.configData[0].chat_id,
        welcome_text: props.configData[0].welcome_text
      })
      setDisabled(true)
    }

  }, [props.configData])

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (initialValues) => {
      setbtnLoad(true)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues),
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/wishlist/telegram/setting/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setbtnLoad(false)
          if (Object.prototype.hasOwnProperty.call(data, "_id")) {
            props.setConfigData([data]);
            props.popupChange(false);
            toast.success('Saved Sucessfully');
          } else {
            toast.error(data.message);
          }
        });
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack mt={1}>
          <TextField
            fullWidth
            autoComplete="token"
            type="text"
            variant="standard"
            label="Token"
            disabled={disabled}
            {...getFieldProps('token')}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
          <TextField sx={{ mt: "10px" }}
            fullWidth
            autoComplete="chat_id"
            type="text"
            variant="standard"
            label="Admin's Chat ID"
            {...getFieldProps('chat_id')}
            error={Boolean(touched.chat_id && errors.chat_id)}
            helperText={touched.chat_id && errors.chat_id}
          />
          <TextField sx={{ mt: "10px" }}
            fullWidth
            autoComplete="welcome_text"
            type="text"
            multiline
            rows={4}
            variant="standard"
            label="Welcome Text for User"
            {...getFieldProps('welcome_text')}
            error={Boolean(touched.welcome_text && errors.welcome_text)}
            helperText={touched.welcome_text && errors.welcome_text}
          />
        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Save
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
