import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack, Switch, FormControlLabel, FormControl, InputLabel, Select, MenuItem,
  TextField,
  Button, Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';
// ----------------------------------------------------------------------
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    backgroundColor: '#d50000',
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function CouponAdd(props) {
  const [btnLoad, setbtnLoad] = useState(false);
  // const [type, setType] = useState('standard');
  const [planData, setPlanData] = useState([]);
  const [state, setState] = useState({
    plan: '',
    amazon: 'amazon',
    flipkart: 'flipkart',
    others: 'earnkaro',
    type: 'singleuser'
  })

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.accessToken}`
      },
    };
    fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/master/plan", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setbtnLoad(false)
        setPlanData(data);
      });

  }, []);

  const LoginSchema = Yup.object().shape({
    expire: Yup.string().required('Expire is required'),
    name: Yup.string().required('Name is required'),
    apply_times_allowed: Yup.string().required('Applytimes is required'),
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      expire: '',
      lifetime: false,
      amazon: 'amazon',
      flipkart: 'flipkart',
      others: 'earnkaro',
      apply_times_allowed: '',
      type: 'singleuser'
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (initialValues) => {
      setbtnLoad(true)
      initialValues.plan = state.plan;
      initialValues.amazon = state.amazon;
      initialValues.flipkart = state.flipkart;
      initialValues.others = state.others;
      initialValues.type = state.type;
      console.log(initialValues)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues),
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/master/coupon", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setbtnLoad(false)
          if (Object.prototype.hasOwnProperty.call(data, "_id")) {
            setbtnLoad(false)
            props.setdataShow([...props.prevState, data]);
            props.popupChange(false);
            toast.success('Saved Sucessfully');
          } else {
            setbtnLoad(false)
            toast.error(data.message);
          }
        });
    }
  });
  // console.log(type);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };

  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  // console.log(type)
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                variant="standard"
                label="Coupon Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="plan">Plan</InputLabel>
                <Select
                  labelId="plan"
                  id="plan_select"
                  value={state.plan}
                  name="plan"
                  onChange={handleChange}

                >
                  {(planData.length > 0 && planData !== undefined) ? planData.map((item, i) => (
                    <MenuItem key={i} value={item._id}>{item.name}</MenuItem>
                  )) : <MenuItem value="">No data Found</MenuItem>}

                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                autoComplete="expire"
                type="text"
                variant="standard"
                label="Expire"
                {...getFieldProps('expire')}
                error={Boolean(touched.expire && errors.expire)}
                helperText={touched.expire && errors.expire}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                autoComplete="apply_times_allowed"
                type="text"
                variant="standard"
                label="Apply Times Allowed"
                {...getFieldProps('apply_times_allowed')}
                error={Boolean(touched.apply_times_allowed && errors.apply_times_allowed)}
                helperText={touched.apply_times_allowed && errors.apply_times_allowed}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="amazon">Amazon</InputLabel>
                <Select
                  labelId="amazon"
                  id="amazon_select"
                  value={state.amazon}
                  name="amazon"
                  onChange={handleChange}

                >
                  <MenuItem value="amazon">Amazon</MenuItem>
                  <MenuItem value="earnkaro">Earnkaro</MenuItem>
                  <MenuItem value="inr_deals">Inr Deals</MenuItem>
                  <MenuItem value="cuelinks">Cuelinks</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="flipkart">Flipkart</InputLabel>
                <Select
                  labelId="flipkart"
                  id="flipkart_select"
                  name="flipkart"
                  value={state.flipkart}
                  onChange={handleChange}

                >
                  <MenuItem value="flipkart">Flipkart</MenuItem>
                  <MenuItem value="earnkaro">Earnkaro</MenuItem>
                  <MenuItem value="inr_deals">Inr Deals</MenuItem>
                  <MenuItem value="cuelinks">Cuelinks</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="others">Others</InputLabel>
                <Select
                  labelId="others"
                  id="others_select"
                  value={state.others}
                  name="others"
                  onChange={handleChange}

                >
                  <MenuItem value="earnkaro">Earnkaro</MenuItem>
                  <MenuItem value="inr_deals">Inr Deals</MenuItem>
                  <MenuItem value="cuelinks">Cuelinks</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="type">Type</InputLabel>
                <Select
                  labelId="type"
                  id="type_select"
                  value={state.type}
                  name="type"
                  onChange={handleChange}

                >
                  <MenuItem value="singleuser">Single User</MenuItem>
                  <MenuItem value="multiuser">Multi User</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControlLabel
                control={<Android12Switch {...getFieldProps('lifetime')} checked={values.lifetime} />}
                label="Lifetime"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Save
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
