import React, { useRef } from 'react';

import {Dialog} from '@mui/material';
// import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
// import { makeStyles } from '@mui/styles';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';

// const useStyles = makeStyles(theme => ({
//     dialogWrapper: {
//         padding: theme.spacing(2),
//         position: 'absolute',
//         top: theme.spacing(0)
//     },
//     dialogTitle: {
//         paddingRight: '0px'
//     }
// }))

//   const iframe = '<iframe src="https://www.youtube.com/embed/JbDktrsnH40" width="540" height="450"></iframe>'; 

export default function Video(props) {
    const dialogBox = useRef()
    // const theme = useTheme();
    const { title, openPopup, url, setOpenPopup } = props;
    // const [fullWidth, setFullWidth] = React.useState(true);
    // const [maxWidth, setMaxWidth] = React.useState('md');

    return (
        <Dialog 
            ref={dialogBox}
            open={openPopup}
            fullWidth
            maxWidth='md'
            onClose={()=>{setOpenPopup(false)}} 
        >
            <DialogTitle style={{ display: 'flex', margin: 0 }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {/* variant='text' > */}
                        <CloseIcon color="error" onClick={()=>{setOpenPopup(false)}}/>
                    {/* > */}
            </DialogTitle>
            {/* <DialogContent> */}
            <iframe
                height="400"
                src={url}
                frameBorder="0"
                allowFullScreen
                title="Embedded youtube"
                />
            {/* </DialogContent> */}
        </Dialog>
    )
}