import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack,
  TextField,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function Coupon(props) {
  const [btnLoad, setbtnLoad] = useState(false);

  useEffect(() => {

  }, []);


  const LoginSchema = Yup.object().shape({
    coupon: Yup.string().required('Coupon Code is required'),
  });
  const formik = useFormik({
    initialValues: {
      coupon: '',
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (initialValues) => {
      setbtnLoad(true)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues),
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/plans/coupon", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setbtnLoad(false)
          if (data.plan) {
            setbtnLoad(false)
            props.popupChange(false);
            toast.success('Saved Sucessfully');
            window.location.reload();

          } else {
            setbtnLoad(false)
            toast.error(data.message);
          }
        });
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack mt={2}>
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label="Coupon Code"
            {...getFieldProps('coupon')}
            error={Boolean(touched.coupon && errors.coupon)}
            helperText={touched.coupon && errors.coupon}
          />
        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Apply
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
