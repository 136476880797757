import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import CryptoJS from "crypto-js"

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [btnLoad, setbtnLoad] = useState(false);

  const RegisterSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Username required'),
    mobile: Yup.string().min(10, 'Too Short!').max(10, 'Too Long!').required('Mobile required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(7, 'Too Short! Min 7 allowed').required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      mobile: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (initialValues) => {
      // console.log(initialValues);
      setbtnLoad(true)
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(initialValues),
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users/signup", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (Object.prototype.hasOwnProperty.call(data, "token")) {
            // aat = affilater access token
            // aln = affilater login bundle
            localStorage.setItem("aat", data.token);
            const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data.users), 'secret key 123').toString();
            localStorage.setItem("aln_bundle", ciphertext);
            setbtnLoad(false)
            toast.success('Registered Successfully');
            navigate('/', { replace: true });
          } else {
            setbtnLoad(false);
            toast.error(data.message);
          }
        });
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                variant="standard"
                fullWidth
                label="Username"
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />

              <TextField
                variant="standard"
                fullWidth
                label="Mobile"
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Stack>

            <TextField
              variant="standard"
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              variant="standard"
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={btnLoad}
            >
              Register
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
