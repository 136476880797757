import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/login';
import Register from './pages/Register';
import OwnShortner from './pages/own_shortner/index';
import Footer from './pages/header_footer/Footer';
import Header from './pages/header_footer/Header';
import DashboardApp from './pages/DashboardApp';
import Converter from './pages/Converter';
import Bitly from './pages/bitly/index';
import Telegram from './pages/telegram/index';
import Twitter from './pages/twitter/index';
import Whitelist from './pages/whitelist/index';
import Forwarder from './pages/forwarder/index';
import Blacklist from './pages/blacklist/index';
import ReplaceUrl from './pages/replacer/replace_url';
import ReplacePost from './pages/replacer/replace_post';
import AffilateSettings from './pages/affilate_setting/index';
import AmazonApiSetting from './pages/amazon_api/index';
import WhishList from './pages/whishlist/index';
import Wordpress from './pages/wordpress/index';
import Blogger from './pages/blogger/index';
import WhatsApp from './pages/whatsapp/index';
import Plan from './pages/master/plan/index';
import Coupon from './pages/master/coupon/index';
import UserPlan from './pages/master/user_plan/index';
import UserAddon from './pages/master/user_addon/index';
import MasterWhatsapp from './pages/master/whatsapp/index';
import MasterForwarder from './pages/master/forwarder/index';
import MasterBlogger from './pages/master/blogger/index';
import Users from './pages/master/users/index';
import NotFound from './pages/Page404';
import Pricing from './pages/pricing/index';

export default function Router() {
    const aat = localStorage.getItem('aat');
    const bundle = localStorage.getItem('aln_bundle');
    let isLoggedIn = false;
    if(aat && bundle){
        isLoggedIn = true;
    }
    return useRoutes(isLoggedIn?[{
        path: '/',
        element: < DashboardLayout />,
        children:[{
            element: < Navigate to="/dashboard"
                replace />
        },
        { path: 'dashboard', element: < DashboardApp /> },
        { path: 'converter', element: < Converter /> },
        { path: 'bitly', element: < Bitly /> },
        { path: 'whitelist', element: < Whitelist /> },
        { path: '/telegram/config', element: < Telegram /> },
        { path: '/telegram/forwarder', element: < Forwarder /> },
        { path: '/telegram/whishlist', element: < WhishList /> },
        { path: 'blacklist', element: < Blacklist /> },
        { path: '/social/telegram', element: < Telegram /> },
        { path: '/social/twitter', element: < Twitter /> },
        { path: '/social/whatsapp', element: < WhatsApp /> },
        { path: '/social/wordpress', element: < Wordpress /> },
        { path: '/social/blogger', element: < Blogger /> },
        { path: '/replacer/replace-url', element: < ReplaceUrl /> },
        { path: '/replacer/replace-post', element: < ReplacePost /> },
        { path: '/shortner/bitly', element: < Bitly /> },
        { path: '/shortner/own-shortner', element: < OwnShortner /> },
        { path: '/setting/affilate', element: < AffilateSettings /> },
        { path: '/setting/footer', element: < Footer /> },
        { path: '/setting/header', element: < Header /> },
        { path: '/setting/amazon-api', element: < AmazonApiSetting /> },
        { path: '/master/users', element: < Users /> },
        { path: '/master/plan', element: < Plan /> },
        { path: '/master/coupon', element: < Coupon /> },
        { path: '/master/whatsapp', element: < MasterWhatsapp /> },
        { path: '/master/forwarder', element: < MasterForwarder /> },
        { path: '/master/user-plan', element: < UserPlan /> },
        { path: '/master/user-addon', element: < UserAddon /> },
        { path: '/master/blogger', element: < MasterBlogger /> },
        { path: '/pricing', element: < Pricing /> },
        { path: '*', element: < Navigate to="/dashboard"
        replace />},]
    }] : [
    {
        path: '/',
        element: < LogoOnlyLayout />,
        children: [
            { path: '/', element: < Login /> },
            { path: '/login', element: < Login /> },
            { path: 'register', element: < Register /> },
            { path: '404', element: < NotFound /> },
            { path: '*', element: < Navigate to="/login"
            replace /> }
        ]
    }]
    // [{
    //     path: '*',
    //     element: < Navigate to="/404"
    //         replace />
    // }]
    );
}
