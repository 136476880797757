import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'react-moment';
import { Grid, Button, Card, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Popup from '../../components/Popup';
import Page from '../../components/Page';
import PricingPlanCard from './PricingPlanCard';
import PricingAddon from './PricingAddon';
//
import LoadingScreen from '../../components/LoadingScreen';
import Coupon from './Coupon';


// ----------------------------------------------------------------------

export default function Pricing() {
  const navigate = useNavigate();
  const [id] = useState(null);
  const [accessToken, setAccessToken] = useState();
  const [allPlans] = useState([]);
  const [allAddons] = useState([]);
  const [userPlan, setuserPlan] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: theme.palette.primary.darker,
    backgroundColor: theme.palette.primary.lighter
  }));

  useEffect(() => {
    const bundle = localStorage.getItem("aat");
    setLoading(true);
    if (bundle != null) {
      setAccessToken(bundle);
    } else {
      toast.error('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };
    fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/plans", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setuserPlan(data.userplan)
        if (data.plans.length !== undefined && data.plans.length > 0) {
          data.plans.forEach((arrayItem) => (
            arrayItem.type === 'standard' ? allPlans.push(arrayItem) : allAddons.push(arrayItem)
          ));
          setLoading(false);
        } else {
          toast.error(data.message);
        }

      });
  }, []);
  // -----------Apply Coupon Popup-----------------
  const handleClickOpen = () => {
    setOpen((add) => !add);
  }
  const handleClickClose = () => {
    setOpen((add) => !add);
  }
  const setOpenPopup = () => {
    setOpen((add) => !add);
  };

  return (
    <Page title="Pricing | Affiliaters">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {isLoading ? <LoadingScreen sx={{
        top: 40,
        left: 0,
        width: 1,
        zIndex: 9999,
        position: 'fixed'
      }} /> :
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Plans & Addons
            </Typography>
            <div>
              <span >
                Have a coupon code?
              </span>
              <Button
                variant="text"
                to="#"
                color="secondary"
                onClick={handleClickOpen}
              >
                Click Here
              </Button>
            </div>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6} >
                  <RootStyle sx={{ p: 2, mb: 1, backgroundColor: '#FFF7CD', textAlign: 'center' }} >
                    <Typography variant="h4" gutterBottom >Plan</Typography>
                    {(userPlan.plan && userPlan.plan.plan) ?
                      <>
                        <Typography gutterBottom>
                          Current Plan : {userPlan.plan.plan ? userPlan.plan.plan.name : ''}
                        </Typography>
                        <Typography gutterBottom>
                          Expiry Date : {userPlan.plan.lifetime ? 'Never ending*' : <Moment format="DD-MM-YYYY">
                            {userPlan.plan.expires}
                          </Moment>}
                        </Typography>

                      </>
                      :
                      <Typography>
                        No Active Plan Found.
                      </Typography>
                    }
                  </RootStyle>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} >
                  <RootStyle sx={{ p: 2, mb: 1, textAlign: 'center' }}>
                    <Typography variant="h4" gutterBottom>Addon</Typography>
                    {(userPlan.addon && userPlan.addon.plan) ?
                      <>
                        <Typography gutterBottom>
                          Current Addon : {userPlan.addon.plan ? userPlan.addon.plan.name : ''}
                        </Typography>
                        <Typography gutterBottom>
                          Expiry Date : {userPlan.addon.lifetime ? 'Never ending*' : <Moment format="DD-MM-YYYY">{userPlan.addon.expires}</Moment>}
                        </Typography>
                      </>
                      :
                      <Typography>
                        No Addons Found.
                      </Typography>
                    }
                  </RootStyle>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack direction="column">
            <Typography variant="h3" align="center" paragraph sx={{ my: 5 }}>
              Standard Plans
            </Typography>

            <Grid container spacing={3}>
              {(allPlans.length > 0 && allPlans !== undefined) ?
                <>{allPlans.map((card, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <PricingPlanCard card={card} index={index} userPlan={userPlan} />
                  </Grid>
                ))}</> : <Grid item xs={12} sm={12} md={12} >
                  <Typography variant="h" gutterBottom>
                    No data found
                  </Typography>
                </Grid>
              }
            </Grid>
          </Stack>
          <Stack direction="column">
            <Typography variant="h3" align="center" paragraph sx={{ my: 5 }}>
              Addons Plans
            </Typography>
            <Grid container spacing={3}>
              {(allAddons.length > 0 && allAddons !== undefined) ?
                <>{allAddons.map((card, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <PricingAddon card={card} index={index} userPlan={userPlan} />
                  </Grid>
                ))}</> : <Grid item xs={12} sm={12} md={12} >
                  <Typography variant="h" gutterBottom>
                    No data found
                  </Typography>
                </Grid>
              }
            </Grid>
          </Stack>
        </Container>}
      <Popup title="Apply Coupon" openPopup={open} setOpenPopup={setOpenPopup}>
        <Coupon popup={open} id={id} accessToken={accessToken} popupChange={handleClickClose} />
      </Popup>
    </Page>
  );
}
