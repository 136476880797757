
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, Card, Button, Container, Stack, Typography, Paper } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Page from '../../components/Page';
// import Label from '../../components/Label';
import { HelpVideo } from '../../components/authentication/help';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

export default function Blogger() {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState('');
    const [data, setData] = useState({});
    const [blogList, setBlogList] = useState([]);
    const [flag, setFlag] = useState('1');
    const [isLoading, setLoading] = useState(false);
    const [isLogin, setLogin] = useState(false);

    const query = new URLSearchParams(useLocation().search);
    const getCode = query.get("code");
    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);
        setLogin(true);
        if (bundle != null) {
            setAccessToken(bundle);
        } else {
            toast.error('No Token Found');
            navigate('/login', { replace: true });
        }

        if (getCode !== null && flag === '1') {
            const uri = window.location.href.split("?")[0];
            const bundle = localStorage.getItem("aat");
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify({ "access_code": getCode, redirect_uri: uri })
            };
            setFlag('2');
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blogger", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (Object.prototype.hasOwnProperty.call(data, "success")) {
                        setBlogList(data.blogList)
                        setData(data.data)
                    } else {
                        toast.error(data.message)
                    }
                    setLoading(false);
                    setLogin(false)
                });


        } else {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${bundle}`
                },
            };
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blogger", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.blogList.length !== undefined && data.blogList.length > 0) {
                        setLoading(false);
                        setBlogList(data.blogList)
                    } else {
                        setLoading(false);
                        toast.error('Bloglist is empty')
                    }
                    if (Object.prototype.hasOwnProperty.call(data.data, "_id")) {
                        setLogin(false)
                        setData(data.data)
                        // toast.success('Login Success')
                    } else {
                        setLogin(false)
                        toast.error('Please Login')
                    }

                });
        }


    }, [])

    const handleAuthClick = () => {
        const uri = encodeURI(window.location.href.split("?")[0]);
        window.open(`https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fblogger&redirect_uri=${uri}&response_type=code&client_id=269564805001-frc48pknbco2lqf0205brkkoqvt0deqk.apps.googleusercontent.com&access_type=offline&prompt=consent`, "_self");
    }


    const handlePostClick = (e, item) => {
        setLoading(true);
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ "blog_id": item.id, "url": item.url })
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blogger", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "success")) {
                    setBlogList(data.blogList)
                    setData(data.data)
                } else {
                    toast.error(data.message)
                }

            });
        setLoading(false)
    }

    const handleLogoutClick = () => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        };
        fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/blogger`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "success")) {
                    setBlogList([]);
                    setData({})
                } else {
                    toast.error(data.message);
                }
            });
    }
    return (
        <Page title="Blogger">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLogin ? <LoadingScreen sx={{
                top: 40,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed'
            }} /> :
                <Container>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Grid container >
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography variant="h4" gutterBottom>
                                    Blogger
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} justifyContent="center">
                                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 10, sm: 1, md: 1 }}>
                                        {data._id ?
                                            <Button
                                                variant="contained"
                                                component={RouterLink}
                                                to="#"
                                                color="warning"
                                                onClick={handleLogoutClick}
                                            >
                                                Logout
                                            </Button> :
                                            <Button
                                                variant="contained"
                                                component={RouterLink}
                                                to="#"
                                                color="warning"
                                                onClick={handleAuthClick}
                                            >
                                                Login via Gmail
                                            </Button>
                                        }
                                    </Stack>

                                </Stack>

                            </Grid>
                        </Grid>
                    </Stack>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            {/* <Card sx={{ p: 0, mb: 3 }}>
                            <Accordion sx={{ p: 0, m: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Filters
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
                                        <ForwarderSearch prevState={blogList} totalData={totalData} setblogList={setblogList} setlimit={setLimit} seterror={setError} />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Card> */}
                            {isLoading ? <LoadingScreen sx={{
                                top: 40,
                                left: 0,
                                width: 1,
                                zIndex: 9999,
                                position: 'fixed'
                            }} /> :
                                <Card sx={{ p: 3 }}>
                                    {(blogList.length > 0 && blogList !== undefined) ? blogList.map((item, i) => (
                                        <Paper
                                            key={i}
                                            sx={{
                                                p: 3,
                                                width: 1,
                                                marginBottom: 1,
                                                bgcolor: 'background.neutral'
                                            }}
                                        >
                                            <Typography variant="body2" gutterBottom sx={{
                                                flexWrap: 'wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Blog ID: &nbsp;
                                                </Typography>
                                                {item.id}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Blog Name: &nbsp;
                                                </Typography>
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Blog URL: &nbsp;
                                                </Typography>
                                                {item.url}
                                            </Typography>
                                            {data.blog_id === item.id ?
                                                <Button
                                                    variant="contained"
                                                    component={RouterLink}
                                                    to="#"
                                                    color="warning"
                                                    disabled
                                                // onClick={handleLogoutClick}
                                                >
                                                    Posting
                                                </Button> :
                                                <Button
                                                    variant="contained"
                                                    component={RouterLink}
                                                    to="#"
                                                    color="warning"
                                                    onClick={(e) => handlePostClick(e, item)}
                                                >
                                                    Start Posting
                                                </Button>
                                            }
                                        </Paper>
                                    )) : <Grid item xs={12} sm={12} md={12} p={2}>
                                        {data._id ? <Typography variant="h4" display="block" gutterBottom>
                                            No Blog found
                                        </Typography> : <Typography variant="h4" display="block" gutterBottom>
                                            Please Login
                                        </Typography>}
                                    </Grid>}
                                </Card>}

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <HelpVideo title="Forwarder" />
                        </Grid>

                    </Grid>
                </Container>
            }
        </Page>

    );
}