import React from 'react'
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Stack, Typography, Grid } from '@mui/material';
// components
import Page from '../../components/Page';
import { HelpVideo } from '../../components/authentication/help';

import  OwnShortnerForm  from './OwnShortnerForm';
// import LoadingScreen from '../../components/LoadingScreen';


// ----------------------------------------------------------------------


export default function OwnShortner() {
  // const [isLoading, setLoading] = useState(true);
  // console.log(isLoading);
  // const handleLoading = () => {
  //   setLoading(false);
  // }
    return (
          <Page title="Own Shortner">
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                Add your Custom shortner
                </Typography>
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  {/* <Card sx={{ p: 3 }}> */}
                    <OwnShortnerForm />
                  {/* </Card> */}
                </Grid>
                <Grid item xs={12} md={4}>
                  <HelpVideo title="Shortner" url="https://www.youtube.com/embed/JbDktrsnH40"/>
                </Grid>
              </Grid>
            </Container>
          </Page>
      );
}
