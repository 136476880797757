import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// material
import {
    Stack, TextField, Grid,
    Card
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { HelpVideo } from '../../help';
import { HelpVideo } from '../../components/authentication/help';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="https://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function HeaderForm() {
    const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    // const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({
        after_post: '',
        password: '',
        header_text: '',
        telegram: false,
        whatsapp: false,
        twitter: false,
        alert: false,
    });
    const [token, setToken] = useState('');
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        // console.log(bundle);
        if (bundle != null) {
            setToken(bundle)
        } else {
            alert("no token found");
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/header", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    setData({
                        after_post: data.after_post,
                        header_text: data.header_text,
                        telegram: data.telegram,
                        whatsapp: data.whatsapp,
                        twitter: data.twitter
                    })
                    setLoading(false);

                } else {
                    setLoading(false);

                    // alert(data.message);
                }
            }).catch((e) => {
                alert(e.message);
                setLoading(false);
            });
            
    }, []);

    // console.log("dataaaaaaa", data)

    const RegisterSchema = Yup.object().shape({
        header_text: Yup.string().required('Header text is required'),
        after_post: Yup.string().required('After post required')
    });

    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: RegisterSchema,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(initialValues),
            };
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/header", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        setbtnLoad(false)
                        setData({
                            after_post: data.after_post,
                            header_text: data.header_text,
                            telegram: data.telegram,
                            whatsapp: data.whatsapp,
                            twitter: data.twitter
                        })
                        toast.success('Saved Sucessfully');
                    } else {
                        setbtnLoad(false)
                        alert(data.message);
                        toast.error(`${data.message}`);
                    }
                });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;


    return (
        <FormikProvider value={formik}>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    {isLoading ? <LoadingScreen  sx={{
                        top: 40,
                        left: 0,
                        width: 1,
                        zIndex: 9999,
                        position: 'fixed'
                    }}/> 
                    :
                    <Card sx={{ p: 3 }}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Header text"
                                    multiline
                                    rows={4}
                                    value={values.header_text}
                                    {...getFieldProps('header_text')}
                                    error={Boolean(touched.header_text && errors.header_text)}
                                    helperText={touched.header_text && errors.header_text}
                                />
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    value={values.after_post}
                                    label="After Every How Many Posts"
                                    {...getFieldProps('after_post')}
                                    error={Boolean(touched.after_post && errors.after_post)}
                                    helperText={touched.after_post && errors.after_post}
                                />


                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                                    <FormControlLabel style={{ margin: 0 }}
                                        control={<Android12Switch {...getFieldProps('telegram')} checked={values.telegram} />}
                                        label="Post on Telegram"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Android12Switch {...getFieldProps('whatsapp')} checked={values.whatsapp} />}
                                        label="Post on Whatsapp"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        control={<Android12Switch {...getFieldProps('twitter')} checked={values.twitter} />}
                                        label="Post on Twitter"
                                        labelPlacement="end"
                                    />
                                </Stack>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={btnLoad}
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </Card>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <HelpVideo title="Header" url="https://www.youtube.com/embed/JbDktrsnH40" />
                </Grid>
            </Grid>
        </FormikProvider >
    );
}
