import React from 'react'
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import  WordpressForm  from './WordpressForm';

// ----------------------------------------------------------------------


export default function Wordpress() {
    return (
        <Page title="Wordpress">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Wordpress
                    </Typography>
                </Stack>
                <WordpressForm />
            </Container>
        </Page>
    );
}
