import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function ReplacerPostEdit(props) {
    // console.log(props.id)
    // const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    const [replaceUrl, setReplaceUrl] = useState({
        find: '',
        replace: '',
    })
    useEffect(() => {
        setReplaceUrl({
            find: props.id.find,
            replace: props.id.replace,
        });
    }, [props.id]);

    const LoginSchema = Yup.object().shape({
        find: Yup.string().required('Find is required'),
        replace: Yup.string().required('Replace is required'),
    });
    // prop.id Get token call API 

    const formik = useFormik({
        initialValues: replaceUrl,
        enableReinitialize: true,
        validationSchema: LoginSchema,
        onSubmit: (initialValues) => {
            // console.log("initialvalues",initialValues);
            setbtnLoad(true)
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            // console.log(requestOptions);
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/replace/post/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        toast.success('Updated Sucessfully')
                        setbtnLoad(false)
                        props.setdataShow(props.prevState.map(item => (
                            item._id === props.id._id ? { ...data } : item
                        )))
                        props.popupChange(false);
                    } else {
                        setbtnLoad(false)
                        toast.error(data.message)
                    }
                });
            // navigate('/dashboard', { replace: true });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="find"
                        type="text"
                        value={values.find}
                        variant="standard"
                        label="Find"
                        {...getFieldProps('find')}
                        error={Boolean(touched.find && errors.find)}
                        helperText={touched.find && errors.find}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="replace"
                        type="text"
                        value={values.replace}
                        variant="standard"
                        label="Replace"
                        {...getFieldProps('replace')}
                        error={Boolean(touched.replace && errors.replace)}
                        helperText={touched.replace && errors.replace}
                    />
                </Stack>
                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
