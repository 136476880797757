import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Grid, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HelpVideo } from '../../components/authentication/help';
import LoadingScreen from '../../components/LoadingScreen';
// ----------------------------------------------------------------------

export default function WordpressForm() {
    const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    const [data, setData] = useState({
        url: '',
        username: '',
        password: ''
    });
    const [token, setToken] = useState('');
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);

        if (bundle != null) {
            setToken(bundle)
        } else {
            alert("no token found");
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/wordpress", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    setData({
                        url: data.url,
                        username: data.username,
                        password: data.password,
                    })
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [navigate]);

    const RegisterSchema = Yup.object().shape({
        url: Yup.string().required('URL is required'),
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: RegisterSchema,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            console.log(initialValues)
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(initialValues),
            };
            fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/wordpress", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        setbtnLoad(false)
                        setData({
                            url: data.url,
                            username: data.username,
                            password: data.password,
                        })
                        toast.success('Saved Sucessfully');
                    } else {
                        setbtnLoad(false)
                        toast.error(`${data.message}`);
                    }
                });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    {isLoading ? <LoadingScreen sx={{
                        top: 40,
                        left: 0,
                        width: 1,
                        zIndex: 9999,
                        position: 'fixed'
                    }} />
                        :
                        <Card sx={{ p: 3 }}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="URL"
                                            value={values.url}
                                            {...getFieldProps('url')}
                                            error={Boolean(touched.url && errors.url)}
                                            helperText={touched.url && errors.url}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={values.username}
                                            label="Username"
                                            {...getFieldProps('username')}
                                            error={Boolean(touched.username && errors.username)}
                                            helperText={touched.username && errors.username}
                                        />
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Password"
                                            value={values.password}
                                            {...getFieldProps('password')}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <LoadingButton
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            loading={btnLoad}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Stack>
                                </Stack>

                            </Form>
                        </Card>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <HelpVideo title="Wordpress" url="https://www.youtube.com/embed/JbDktrsnH40" />
                </Grid>
            </Grid>
        </FormikProvider >
    );
}
