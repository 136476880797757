// import { useState} from 'react';
// import { Icon } from '@iconify/react';
// import moonFill from '@iconify/icons-eva/moon-fill';
// import sunFill from '@iconify/icons-eva/sun-fill';
// material
// import { Box, Grid, Radio, Paper, RadioGroup, CardActionArea, FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlined from '@mui/icons-material/LightModeOutlined';
// hooks
import useSettings from '../../hooks/useSettings';
// import { SettingsContext } from '../../contexts/SettingsContext';

// ----------------------------------------------------------------------

export default function SettingMode() {
  const { themeMode, onChangeMode } = useSettings();
  // const [ theme, setTheme ] = useState();
  const handleChange = () => {
    if(themeMode === 'dark') {
      onChangeMode('light')
    } else {
      onChangeMode('dark')
    }
  }
  
  return (
    <Tooltip title={themeMode=== 'dark' ? 'Turn on the light' : 'Turn off the light'}>
      <IconButton color={themeMode=== 'dark' ? 'warning' : 'primary'} disableTouchRipple onClick={(event) => handleChange(event)}>
        {themeMode=== 'dark' ? (
          <LightModeOutlined fontSize="small" />
        ) : (
          <DarkModeOutlined fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
    // <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode}>
    //   <Grid container spacing={2.5} dir="ltr">
    //     {['light', 'dark'].map((mode, index) => (
    //       <Grid item xs={6} key={mode}>
    //         <Paper
    //           sx={{
    //             width: 1,
    //             zIndex: 0,
    //             overflow: 'hidden',
    //             position: 'relative',
    //             bgcolor: mode === 'dark' ? 'grey.900' : 'common.white',
    //             ...(themeMode === mode && {
    //               boxShadow: (theme) => theme.customShadows.z12
    //             })
    //           }}
    //         >
    //           <CardActionArea sx={{ color: 'primary.main' }}>
    //             <Box
    //               sx={{
    //                 py: 4,
    //                 display: 'flex',
    //                 color: 'text.disabled',
    //                 justifyContent: 'center',
    //                 ...(themeMode === mode && {
    //                   color: 'primary.main'
    //                 })
    //               }}
    //             >
    //               <Icon icon={index === 0 ? sunFill : moonFill} width={24} height={24} />
    //             </Box>

    //             <FormControlLabel
    //               label=""
    //               value={mode}
    //               control={<Radio sx={{ display: 'none'}} />}
    //               sx={{
    //                 top: 0,
    //                 margin: 0,
    //                 width: '100%',
    //                 height: '100%',
    //                 position: 'absolute'
    //               }}
    //             />
    //           </CardActionArea>
    //         </Paper>
    //       </Grid>
    //     ))}
    //   </Grid>
    // </RadioGroup>
  );
}
