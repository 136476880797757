// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
// import Settings from './components/settings';

import ThemePrimaryColor from './components/ThemePrimaryColor';


// import LoadingScreen from './components/LoadingScreen';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------

export default function App() {
  // const aat = localStorage.getItem('aat');
  // const bundle = localStorage.getItem('aln_bundle');
  // let isLoggedIn = false;
  // if(aat && bundle){
  //     isLoggedIn = true; 
  // }
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
