import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => < Icon icon={name}
    width={22}
    height={22}
/>;

const sidebarConfig = [
    {
        subheader: 'general',
        items: [
        {
            title: 'dashboard',
            path: '/dashboard',
            icon: getIcon(pieChart2Fill)
        },
        {
            title: 'converter',
            path: '/converter',
            icon: getIcon(peopleFill)
        },
        {
            title: 'Whitelist',
            path: '/whitelist',
            icon: getIcon(shoppingBagFill)
        },
        {
            title: 'Blacklist',
            path: '/blacklist',
            icon: getIcon(shoppingBagFill)
        },
        {
            title: 'pricing',
            path: '/pricing',
            icon: getIcon(pieChart2Fill)
        },
        ]
      },
    {
        subheader: 'Shortner',
        items: [
        // MANAGEMENT : USER
            {
                title: 'Shortner',
                path: '/shortner',
                icon: getIcon(alertTriangleFill),
                children: [
                { title: 'Bitly Shortner', path: '/shortner/bitly' },
                { title: 'Own Shortner', path: '/shortner/own-shortner' },
                ]
            }
        ]
    },
    {
        subheader: 'Social',
        items: [
        // MANAGEMENT : USER
            {
                title: 'Social',
                path: '/social',
                icon: getIcon(fileTextFill),
                children: [
                // { title: 'Telegram', path: '/social/telegram' },
                { title: 'Whatsapp', path: '/social/whatsapp' },
                { title: 'Twitter', path: '/social/twitter' },
                { title: 'Wordpress', path: '/social/wordpress' },
                { title: 'Blogger', path: '/social/blogger' },
                ]
            }
        ]
    },
    {
        subheader: 'Telegram',
        items: [
        // MANAGEMENT : USER
            {
                title: 'Telegram',
                path: '/telegram',
                icon: getIcon(fileTextFill),
                children: [
                { title: 'Config', path: '/telegram/config' },
                { title: 'Forwarder', path: '/telegram/forwarder' },
                { title: 'Whishlist', path: '/telegram/whishlist' },
                ]
            }
        ]
    },
    {
        subheader: 'Replacer',
        items: [
        // MANAGEMENT : USER
            {
                title: 'Replacer',
                path: '/replacer',
                icon: getIcon(fileTextFill),
                children: [
                { title: 'Replace URL', path: '/replacer/replace-url' },
                { title: 'Replace POST', path: '/replacer/replace-post' },
                ]
            }
        ]
    },
    {
        subheader: 'Settings',
        items: [
        // MANAGEMENT : USER
            {
                title: 'Settings',
                path: '/setting',
                icon: getIcon(fileTextFill),
                children: [
                { title: 'Header', path: '/setting/header' },
                { title: 'Footer', path: '/setting/footer' },
                { title: 'Affiliate Settings', path: '/setting/affilate' },
                { title: 'Amazon API Settings', path: '/setting/amazon-api' },
                ]
            }
        ]
    },
    {
        subheader: 'Master',
        items: [
        // MANAGEMENT : USER
            {
                title: 'Master',
                path: '/master',
                icon: getIcon(fileTextFill),
                children: [
                { title: 'Users', path: '/master/users' },
                { title: 'Plan', path: '/master/plan' },
                { title: 'Coupon', path: '/master/coupon' },
                { title: 'WhatsApp', path: '/master/whatsapp' },
                { title: 'Forwarder', path: '/master/forwarder' },
                { title: 'User Plan', path: '/master/user-plan' },
                { title: 'User Addon', path: '/master/user-addon' },
                { title: 'Blogger', path: '/master/blogger' },
                ]
            }
        ]
    }
];

export default sidebarConfig;