// import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Field, useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button, FormControlLabel, Grid, Switch, Select, MenuItem
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function CouponEdit(props) {
    // console.log(props.id)
    const [btnLoad, setbtnLoad] = useState(false);
    const [planData, setPlanData] = useState([]);
    const [coupon, setCouponData] = useState({
        name: '',
        expire: '',
        lifetime: false,
        apply_times_allowed: '',
        plan: '',
        amazon: '',
        flipkart: '',
        others: '',
        type: ''
    })
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${props.accessToken}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/master/plan", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPlanData(data);
            });

    }, []);

    useEffect(() => {
        setCouponData({
            name: props.id.name,
            expire: props.id.expire,
            lifetime: props.id.lifetime,
            apply_times_allowed: props.id.apply_times_allowed,
            plan: props.id.plan._id,
            amazon: props.id.amazon,
            flipkart: props.id.flipkart,
            others: props.id.others,
            type: props.id.type,
        });
    }, [props.id]);

    const formik = useFormik({
        initialValues: coupon,
        enableReinitialize: true,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/master/coupon/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        toast.success('Updated Sucessfully')
                        setbtnLoad(false)
                        props.setdataShow(props.prevState.map(item => (
                            item._id === props.id._id ? { ...data } : item
                        )))
                        props.popupChange(false);
                    } else {
                        setbtnLoad(false)
                        toast.error(data.message)
                    }
                });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    // console.log("coupon", coupon);
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field as={TextField}
                                fullWidth
                                autoComplete="name"
                                type="text"
                                name="name"
                                variant="standard"
                                label="Coupon Name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="plan">Plan</InputLabel>
                                <Field as={Select}
                                    labelId="plan"
                                    id="plan_select"
                                    name="plan"
                                >
                                    {(planData.length > 0 && planData !== undefined) ? planData.map((item, i) => (
                                        <MenuItem key={i} value={item._id}>{item.name}</MenuItem>
                                    )) : <MenuItem value="">No data Found</MenuItem>}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field as={TextField}
                                fullWidth
                                autoComplete="expire"
                                type="text"
                                name="expire"
                                variant="standard"
                                label="Expire"
                                {...getFieldProps('expire')}
                                error={Boolean(touched.expire && errors.expire)}
                                helperText={touched.expire && errors.expire}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field as={TextField}
                                fullWidth
                                autoComplete="apply_times_allowed"
                                type="text"
                                name="apply_times_allowed"
                                variant="standard"
                                label="Apply times allowed"
                                {...getFieldProps('apply_times_allowed')}
                                error={Boolean(touched.apply_times_allowed && errors.apply_times_allowed)}
                                helperText={touched.apply_times_allowed && errors.apply_times_allowed}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="amazon">Amazon</InputLabel>
                                <Field as={Select}
                                    labelId="amazon"
                                    id="amazon_select"
                                    name="amazon"
                                >
                                    <MenuItem value="amazon">Amazon</MenuItem>
                                    <MenuItem value="earnkaro">Earnkaro</MenuItem>
                                    <MenuItem value="inr_deals">Inr Deals</MenuItem>
                                    <MenuItem value="cuelinks">Cuelinks</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="flipkart">Flipkart</InputLabel>
                                <Field as={Select}
                                    labelId="flipkart"
                                    id="flipkart_select"
                                    name="flipkart"
                                >
                                    <MenuItem value="flipkart">Flipkart</MenuItem>
                                    <MenuItem value="earnkaro">Earnkaro</MenuItem>
                                    <MenuItem value="inr_deals">Inr Deals</MenuItem>
                                    <MenuItem value="cuelinks">Cuelinks</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="others">Others</InputLabel>
                                <Field as={Select}
                                    labelId="others"
                                    id="others_select"
                                    name="others"
                                >
                                    <MenuItem value="earnkaro">Earnkaro</MenuItem>
                                    <MenuItem value="inr_deals">Inr Deals</MenuItem>
                                    <MenuItem value="cuelinks">Cuelinks</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="type">Type</InputLabel>
                                <Field as={Select}
                                    labelId="type"
                                    id="type_select"
                                    name="type"
                                >
                                    <MenuItem value="singleuser">Single User</MenuItem>
                                    <MenuItem value="multiuser">Multi User</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Android12Switch {...getFieldProps('lifetime')} checked={values.lifetime} />}
                                label="lifetime"
                                labelPlacement="end"
                            />

                        </Grid>
                    </Grid>
                </Stack>

                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
