import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import account from '../../_mocks_/account';

const options = ['Active', 'Deactive'];

export default function TelegramSearch(props) {
  const [value, setValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [user, setUser] = React.useState('');
  // const [btnLoad, setbtnLoad] = React.useState(false);

  const arr = [];
  props.totalData.forEach((arrayItem) => (
    arr.push(arrayItem.user.username)
  ));
  const newArr = [...new Set(arr)];

  const channelArr = [];
  props.totalData.forEach((arrayItem) => (
    channelArr.push(arrayItem.channel)
  ));
  const newChannelArr = [...new Set(channelArr)];
// console.log(newChannelArr);
  const allData = props.totalData;
  let filterdata = [];
  const handleResetClick = () =>{
    props.setdataShow(allData)
    setValue('');
    setStatus('');
    setUser('');
    if(allData.length > 15) {
      props.setlimit(5)
      props.seterror(false)
    } else if(allData.length <= 15) {
      props.setlimit(5)
      props.seterror(true)
    } else {
      props.setlimit(0)
      props.seterror(true)
    }
  }
  const handleClick = () => {
    // user search-------------------------------------------------
    if (user) {
      const result = allData.filter((item) => (
        item.user.username === user
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } else {
      filterdata = (filterdata.length ===0) ? allData : filterdata
    }
    // token search ------------------------------------------------------
    if (value && filterdata ){
      const result = filterdata.filter((item) => (
        item.channel === value
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } else {
      filterdata = (filterdata.length ===0) ? allData : filterdata
    }
    // status search --------------------------------------------------------
    if (status && filterdata) {
      const st = (status==='Active' ? 'true' : 'false')==='true'
      const result = filterdata.filter((item) => (
        item.status === st
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } 
    props.setdataShow(filterdata)
    if(filterdata.length > 15) {
      props.setlimit(5)
      props.seterror(false)
    } else if(filterdata.length <= 15) {
      props.setlimit(5)
      props.seterror(true)
    } else {
      props.setlimit(0)
      props.seterror(true)
    }
  }
  return (
    <>
      <Grid container spacing={2}>
          
            {account.pass_key===1 && <Grid item xs={12} md={6}> <Autocomplete
              value={user}
              onChange={(event, newValue) => {
                setUser(newValue);
              }}
              id="controllable-states-demo"
              options={newArr}
              getOptionLabel={(option) => option || ""}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} variant="standard" label="User Search" />}
            /> </Grid>}
          
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              id="controllable-states-demo"
              options={newChannelArr}
              getOptionLabel={(option) => option || ""}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Channel Search" />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={status}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              id="status"
              options={options}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Status Search" />}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{textAlign: 'right'}}>
            <LoadingButton
              type="submit"
              variant="text"
              loading={false}
              onClick={handleClick}
            >
              Search
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="text"
              color="error"
              loading={false}
              onClick={handleResetClick}
            >
              Reset
            </LoadingButton>
          </Grid>
       </Grid>
    </>
  );
}
