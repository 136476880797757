import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete,Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import account from '../../../_mocks_/account';

export default function ReplacerUrlSearch(props) {
  const [find, setFind] = React.useState('');
  const [replace, setReplace] = React.useState('');
  const [user, setUser] = React.useState('');
  // const [btnLoad, setbtnLoad] = React.useState(false);

  const arr = [];
  props.totalData.forEach((arrayItem) => (
    arr.push(arrayItem.user.username)
  ));
  const newArr = [...new Set(arr)];

  const findArr = [];
  props.totalData.forEach((arrayItem) => (
    findArr.push(arrayItem.find)
  ));
  const newFindArr = [...new Set(findArr)];

  const replaceArr = [];
  props.totalData.forEach((arrayItem) => (
    replaceArr.push(arrayItem.replace)
  ));
  const newreplaceArr = [...new Set(replaceArr)];
  const allData = props.totalData;
  let filterdata = [];
  const handleResetClick = () => {
    props.setdataShow(allData)
    setReplace('');
    setUser('');
    setFind('');
    if(allData.length > 15) {
      props.setlimit(5)
      props.seterror(false)
    } else if(allData.length <= 15) {
      props.setlimit(5)
      props.seterror(true)
    } else {
      props.setlimit(0)
      props.seterror(true)
    }
  }
  const handleClick = () => {
    // user search-------------------------------------------------
    if (user) {
      const result = allData.filter((item) => (
        item.user.username === user
      ));
      if (result.length > 0) {
        filterdata = result
      } else {
        filterdata = ''
      }
    } else {
      filterdata = (filterdata.length === 0) ? allData : filterdata
    }
    // find search

    if (find && filterdata ){
      const result = filterdata.filter((item) => (
        item.find === find
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } else {
      filterdata = (filterdata.length ===0) ? allData : filterdata
    }

    // replace search

    if (replace && filterdata ){
      const result = filterdata.filter((item) => (
        item.replace === replace
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } else {
      filterdata = (filterdata.length ===0) ? allData : filterdata
    }
    props.setdataShow(filterdata)
    if(filterdata.length > 15) {
      props.setlimit(5)
      props.seterror(false)
    } else if(filterdata.length <= 15) {
      props.setlimit(5)
      props.seterror(true)
    } else {
      props.setlimit(0)
      props.seterror(true)
    }
  }
  return (
    <>
      <Grid container spacing={2}>

        {account.pass_key === 1 && <Grid item xs={12} md={6}> <Autocomplete
          value={user}
          onChange={(event, newValue) => {
            setUser(newValue);
          }}
          id="controllable-states-demo"
          options={newArr}
          getOptionLabel={(option) => option || ""}
          sx={{ width: '100%' }}
          renderInput={(params) => <TextField {...params} variant="standard" label="User Search" />}
        /> </Grid>}
        <Grid item xs={12} md={6}>
          <Autocomplete
            value={find}
            onChange={(event, newValue) => {
              setFind(newValue);
            }}
            id="find"
            options={newFindArr}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Search Find" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            value={replace}
            onChange={(event, newValue) => {
              setReplace(newValue);
            }}
            id="replace"
            options={newreplaceArr}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} variant="standard" label="Search Replace" />}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
          <LoadingButton
            type="submit"
            variant="text"
            loading={false}
            onClick={handleClick}
          >
            Search
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="text"
            color="error"
            loading={false}
            onClick={handleResetClick}
          >
            Reset
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
