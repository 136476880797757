import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function UsersEdit(props) {
    // console.log(props.id
    const [btnLoad, setbtnLoad] = useState(false);
    const [twitter, setTwitter] = useState({
        username: '',
        email: '',
        mobile: '',
    })
    useEffect(() => {
        setTwitter({
            username: props.id.username,
            email: props.id.email,
            mobile: props.id.mobile,
        });
    }, [props.id]);

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        email: Yup.string().required('Email is required'),
        mobile: Yup.string().required('Mobile is required'),
    });
    // prop.id Get token call API 

    const formik = useFormik({
        initialValues: twitter,
        enableReinitialize: true,
        validationSchema: LoginSchema,
        onSubmit: (initialValues) => {
            // console.log("initialvalues",initialValues);
            setbtnLoad(true)
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        toast.success('Updated Sucessfully')
                        setbtnLoad(false)
                        props.setdataShow(props.prevState.map(item => (
                            item._id === props.id._id ? { ...data } : item
                        )))
                        props.popupChange(false);
                    } else {
                        setbtnLoad(false)
                        toast.error(data.message)
                    }
                });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="text"
                        value={values.username}
                        variant="standard"
                        label="API Key"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="email"
                        type="text"
                        value={values.email}
                        variant="standard"
                        label="API Secret"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="mobile"
                        type="text"
                        value={values.mobile}
                        variant="standard"
                        label="Access Token"
                        {...getFieldProps('mobile')}
                        error={Boolean(touched.mobile && errors.mobile)}
                        helperText={touched.mobile && errors.mobile}
                    />
                </Stack>
                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
