import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button, FormControlLabel, Grid, Switch, Select, MenuItem, Typography
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import { ColorPicker } from 'material-ui-color';


// ----------------------------------------------------------------------
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function PlanEdit(props) {
    const [btnLoad, setbtnLoad] = useState(false);
    //   const [color, setColor] = useState();
    const [color, setColor] = useState({
        textColor: '',
        backColor: '',
        btnColor: ''
    });
    const [mType, setType] = useState('');
    const [plan, setPlanData] = useState({
        name: '',
        amount: '',
        telegram: '',
        twitter: '',
        whatsapp: '',
        bitly: '',
        blogger: false,
        wordpress: false,
        facebook: false,
        forwarder: false,
        type: '',
        popular: false,
        imageUrl: ''
    })
    useEffect(() => {
        setPlanData({
            name: props.id.name,
            amount: props.id.amount,
            telegram: props.id.telegram,
            twitter: props.id.twitter,
            whatsapp: props.id.whatsapp,
            bitly: props.id.bitly,
            blogger: props.id.blogger,
            wordpress: props.id.wordpress,
            facebook: props.id.facebook,
            forwarder: props.id.forwarder,
            type: props.id.type,
            popular: props.id.popular,
            imageUrl: props.id.imageUrl ? props.id.imageUrl : ''
        });
        setType(props.id.type);
        setColor({
            textColor: props.id.textColor,
            backColor: props.id.color,
            btnColor: props.id.buttonColor
        })
    }, [props.id]);
    const LoginSchema = Yup.object().shape({
        name: Yup.string().required('API Key is required'),
        amount: Yup.string().required('API Secret is required'),
        telegram: Yup.string().required('Access Token is required'),
        twitter: Yup.string().required('Token Secret is required'),
        whatsapp: Yup.string().required('Whatsapp is required'),
        bitly: Yup.string().required('Bitly is required'),
        type: Yup.string().required('Type is required'),
    });
    const formik = useFormik({
        initialValues: plan,
        enableReinitialize: true,
        validationSchema: LoginSchema,
        onSubmit: (initialValues) => {
            setbtnLoad(true)
            initialValues.type = mType
            initialValues.color = color.backColor
            initialValues.buttonColor = color.btnColor
            initialValues.textColor = color.textColor
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            console.log(initialValues)
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/master/plan/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        toast.success('Updated Sucessfully')
                        setbtnLoad(false)
                        props.setdataShow(props.prevState.map(item => (
                            item._id === props.id._id ? { ...data } : item
                        )))
                        props.popupChange(false);
                    } else {
                        setbtnLoad(false)
                        toast.error(data.message)
                    }
                });
        }
    });
    const handleColor = (value, type) => {
        setColor((prev) => ({ ...prev, [type]: value.css.backgroundColor }))
    }
    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    const handleChange = (e) => {
        setType(e.target.value);
        // setPlanData((prev)=>({...prev,"type":e.target.value}))
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="name"
                                type="text"
                                variant="standard"
                                label="Plan Name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="amount"
                                type="text"
                                variant="standard"
                                label="Amount"
                                {...getFieldProps('amount')}
                                error={Boolean(touched.amount && errors.amount)}
                                helperText={touched.amount && errors.amount}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={mType}
                                    onChange={handleChange}
                                    label="Age"
                                >
                                    <MenuItem value="standard">Standard</MenuItem>
                                    <MenuItem value="addon">Addon</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="telegram"
                                type="text"
                                variant="standard"
                                label="Telegram"
                                {...getFieldProps('telegram')}
                                error={Boolean(touched.telegram && errors.telegram)}
                                helperText={touched.telegram && errors.telegram}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="twitter"
                                type="text"
                                variant="standard"
                                label="Twitter"
                                {...getFieldProps('twitter')}
                                error={Boolean(touched.twitter && errors.twitter)}
                                helperText={touched.twitter && errors.twitter}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="whatsapp"
                                type="text"
                                variant="standard"
                                label="Whatsapp"
                                {...getFieldProps('whatsapp')}
                                error={Boolean(touched.whatsapp && errors.whatsapp)}
                                helperText={touched.whatsapp && errors.whatsapp}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="bitly"
                                type="text"
                                variant="standard"
                                label="Bitly"
                                {...getFieldProps('bitly')}
                                error={Boolean(touched.bitly && errors.bitly)}
                                helperText={touched.bitly && errors.bitly}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                autoComplete="imageUrl"
                                type="text"
                                variant="standard"
                                label="Image URL"
                                {...getFieldProps('imageUrl')}
                                error={Boolean(touched.imageUrl && errors.imageUrl)}
                                helperText={touched.imageUrl && errors.imageUrl}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Typography>Background Color</Typography>
                            <ColorPicker value={color.backColor} onChange={(e) => handleColor(e, 'backColor')} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Typography>Text Color</Typography>
                            <ColorPicker value={color.textColor} onChange={(e) => handleColor(e, 'textColor')} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Typography>Button Color</Typography>
                            <ColorPicker value={color.btnColor} onChange={(e) => handleColor(e, 'btnColor')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Android12Switch {...getFieldProps('blogger')} checked={values.blogger} />}
                                label="Blogger"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Android12Switch {...getFieldProps('wordpress')} checked={values.wordpress} />}
                                label="Wordpress"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Android12Switch {...getFieldProps('facebook')} checked={values.facebook} />}
                                label="Facebook"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Android12Switch {...getFieldProps('forwarder')} checked={values.forwarder} />}
                                label="Forwarder"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControlLabel
                                control={<Android12Switch {...getFieldProps('popular')} checked={values.popular} />}
                                label="Popular"
                                labelPlacement="end"
                            />
                        </Grid>

                    </Grid>
                </Stack>

                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider >
    );
}
