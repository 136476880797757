import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';
export default function ForwarderSearch(props) {
    const [name, setName] = React.useState('');
    const [btnLoad, setbtnLoad] = React.useState(false);
    const [btnResetLoad, setResetLoad] = React.useState(false);
    const nameArr = [];
    props.totalData.forEach((arrayItem) => (
        nameArr.push(arrayItem.word)
    ));
    const newNameArr = [...new Set(nameArr)];


    const allData = props.totalData;
    let filterdata = [];
    const handleResetClick = () => {
        setResetLoad(true)
        props.setdataShow(allData)
        setName('');
        setResetLoad(false)
    }
    const handleClick = () => {
        setbtnLoad(true)
        // user search-------------------------------------------------
        if (name) {
            const result = allData.filter((item) => (
                item.word === name
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        }

        props.setdataShow(filterdata)
        setbtnLoad(false)
    }
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} md={12}> <Autocomplete
                    value={name}
                    onChange={(event, newValue) => {
                        setName(newValue);
                    }}
                    id="controllable-states-demo"
                    options={newNameArr}
                    getOptionLabel={(option) => option || ""}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} fullWidth variant="standard" label="Name Search" />}
                /> </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                        onClick={handleClick}
                    >
                        Search
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        color="error"
                        loading={btnResetLoad}
                        onClick={handleResetClick}
                    >
                        Reset
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}
