import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
  Stack,
  TextField,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function BitlyEdit(props) {
  // console.log(props)
  // const navigate = useNavigate();
  const [btnLoad, setbtnLoad] = useState(false);
  const [bitlyToken, setBitlyToken] = useState({
    token: ''
  })
  useEffect(() => {
    setBitlyToken({ token: props.id.token });
  }, [props.id]);

  const LoginSchema = Yup.object().shape({
    token: Yup.string().required('Token number is required'),
  });
  // prop.id Get token call API 

  const formik = useFormik({
    initialValues: bitlyToken,
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (initialValues) => {
      // console.log(initialValues);
      setbtnLoad(true)
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues)
      };
      fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/bitly/${props.id._id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (Object.prototype.hasOwnProperty.call(data, "_id")) {
            toast.success('Updated Sucessfully')
            setbtnLoad(false)
            props.setdataShow(props.prevState.map(item => (
              item._id === props.id._id ? { ...data } : item
            )))
            props.popupChange(false);
          } else {
            setbtnLoad(false)
            toast.error(data.message)
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack mt={2}>
          <TextField
            fullWidth
            autoComplete="token"
            type="text"
            value={values.token}
            variant="standard"
            label="Token No."
            {...getFieldProps('token')}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Save
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
