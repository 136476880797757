import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';

// const options = ['Active', 'Deactive'];

export default function UsersSearch(props) {
  const [mobile, setMobile] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [user, setUser] = React.useState('');
  // const [btnLoad, setbtnLoad] = React.useState(false);

  const arr = [];
  props.totalData.forEach((arrayItem) => (
    arr.push(arrayItem.username)
  ));
  const userNameArr = [...new Set(arr)];

  const arr1 = [];
  props.totalData.forEach((arrayItem) => (
    arr1.push(arrayItem.email)
  ));
  const userEmailArr = [...new Set(arr1)];

  const arr2 = [];
  props.totalData.forEach((arrayItem) => (
    arr2.push(arrayItem.mobile)
  ));
  const userMobileArr = [...new Set(arr2)];

  const allData = props.totalData;
  let filterdata = [];
  const handleResetClick = () =>{
    props.setdataShow(allData)
    setMobile('');
    setUser('');
    setEmail('');
    if(allData.length > 15) {
      props.setlimit(5)
      props.seterror(false)
    } else if(allData.length <= 15) {
      props.setlimit(5)
      props.seterror(true)
    } else {
      props.setlimit(0)
      props.seterror(true)
    }
  }
  const handleClick = () => {
    // user search-------------------------------------------------
    if (user) {
      const result = allData.filter((item) => (
        item.username === user
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } else {
      filterdata = (filterdata.length ===0) ? allData : filterdata
    }
    // status search --------------------------------------------------------
    if (email && filterdata) {
      const result = filterdata.filter((item) => (
        item.email === email
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } 

    if (mobile && filterdata) {
      const result = filterdata.filter((item) => (
        item.mobile === mobile
      ));
      if (result.length > 0) {
        filterdata = result
      }else{
        filterdata=''
      }
    } 
    props.setdataShow(filterdata)
    if(filterdata.length > 15) {
      props.setlimit(5)
      props.seterror(false)
    } else if(filterdata.length <= 15) {
      props.setlimit(5)
      props.seterror(true)
    } else {
      props.setlimit(0)
      props.seterror(true)
    }
  }
  return (
    <>
      <Grid container spacing={2}>
          
            <Grid item xs={12} md={6}> <Autocomplete
              value={user}
              onChange={(event, newValue) => {
                setUser(newValue);
              }}
              id="controllable-states-demo"
              options={userNameArr}
              getOptionLabel={(option) => option || ""}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Username Search" />}
            /> </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={email}
              onChange={(event, newValue) => {
                setEmail(newValue);
              }}
              id="email"
              options={userEmailArr}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Email Search" />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={mobile}
              onChange={(event, newValue) => {
                setMobile(newValue);
              }}
              id="mobile"
              options={userMobileArr}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Mobile Search" />}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{textAlign: 'right'}}>
            <LoadingButton
              type="submit"
              variant="text"
              loading={false}
              onClick={handleClick}
            >
              Search
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="text"
              color="error"
              loading={false}
              onClick={handleResetClick}
            >
              Reset
            </LoadingButton>
          </Grid>
       </Grid>
    </>
  );
}
