
import { useState, useEffect } from 'react';
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, Card, Button, ButtonGroup, Container, Stack, Typography, Paper } from '@mui/material';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Switch from '@mui/material/Switch';
// import { styled } from '@mui/material/styles';
// import editFill from '@iconify/icons-eva/edit-fill';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import Page from '../../components/Page';
import Popup from '../../components/Popup';
// import WhitelistEdit from './WhitelistEdit';
import WhishlistLogin from './WhishlistLogin';
import WhishlistSearch from './WhishlistSearch';
import 'react-toastify/dist/ReactToastify.css';
import { HelpVideo } from '../../components/authentication/help';
// import Label from '../../components/Label';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

export default function Whitelist() {
    const navigate = useNavigate();
    // const [openEdit, setOpenEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [dataShow, setdataShow] = useState([]);
    // const [id, setID] = useState(null);
    const [accessToken, setAccessToken] = useState();
    const [totalData, setTotalData] = useState([]);
    const [limit, setLimit] = useState(15);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [configData, setConfigData] = useState([]);

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);
        if (bundle != null) {
            setAccessToken(bundle);
        } else {
            toast.error('No Token Found');
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/wishlist/telegram/setting/", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setLoading(false);
                if (Object.prototype.hasOwnProperty.call(data, "config") && Object.prototype.hasOwnProperty.call(data, "subscribers")) {
                    if (data.config.length !== undefined) {
                        setConfigData(data.config)
                    }
                    if (data.subscribers.length !== undefined) {
                        setdataShow(data.subscribers)
                        setTotalData(data.subscribers);
                        if (limit >= data.subscribers.length) {
                            setError(true);
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            });
    }, []);
    // -----------Add Whitelist Popup-----------------
    const handleClickOpen = () => {
        setOpen((add) => !add);
    }
    const handleClickClose = () => {
        setOpen((add) => !add);
    }
    const setOpenPopup = () => {
        setOpen((add) => !add);
    };
    // -----------Edit Whitelist Popup-----------------
    // const handleEditOpen = (id) => {
    //     setOpenEdit((edit) => !edit);
    //     setID(id);
    // }
    // const handleEditClose = (e) => {
    //     setOpenEdit((edit) => !edit);
    // }
    // const setEditPopup = () => {
    //     setOpenEdit((edit) => !edit);
    // };
    // -----------Delete Whitelist -----------------

    const handleLoadMore = () => {
        if (limit < dataShow.length) {
            setLimit(limit + 5);
            const l = limit + 5;
            if (l >= dataShow.length) {
                setError(true);
            }
        } else {
            setError(true);
        }
    }
    return (
        <Page title="Whishlist Bot">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Whishlist Bot
                    </Typography>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="#"
                            color="primary"
                            //   startIcon={<Icon icon={plusFill} />}
                            onClick={handleClickOpen}
                        >
                            Config
                        </Button>
                    </ButtonGroup>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ p: 0, mb: 3 }}>
                            <Accordion sx={{ p: 0, m: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Filters
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
                                        <WhishlistSearch prevState={dataShow} totalData={totalData} setdataShow={setdataShow} setlimit={setLimit} seterror={setError} />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Card>

                        {isLoading ? <LoadingScreen sx={{
                            top: 40,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed'
                        }} /> :
                            <div>
                                {
                                    configData.length > 0 && <Card sx={{ p: 3, mb: 2 }}>
                                        <Paper
                                            sx={{
                                                p: 3,
                                                width: 1,
                                                marginBottom: 1,
                                                bgcolor: '#C8FACD'
                                            }}
                                        >
                                            <Typography variant="body2" gutterBottom sx={{
                                                flexWrap: 'wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Token: &nbsp;
                                                </Typography>
                                                {configData[0].token}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom sx={{
                                                flexWrap: 'wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Chat ID: &nbsp;
                                                </Typography>
                                                {configData[0].chat_id}
                                            </Typography>
                                        </Paper>
                                    </Card>
                                }
                                <Card sx={{ p: 3 }}>
                                    {(dataShow.length > 0 && dataShow !== undefined) ? dataShow.slice(0, limit).map((item) => (
                                        <Paper
                                            key={item._id}
                                            sx={{
                                                p: 3,
                                                width: 1,
                                                marginBottom: 1,
                                                bgcolor: 'background.neutral'
                                            }}
                                        >
                                            <Typography variant="body2" gutterBottom sx={{
                                                flexWrap: 'wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Name: &nbsp;
                                                </Typography>
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom sx={{
                                                flexWrap: 'wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Chat Id: &nbsp;
                                                </Typography>
                                                {item.chat_id}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom sx={{
                                                flexWrap: 'wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                    Subscribed Items: &nbsp;
                                                </Typography>
                                                {
                                                    item.items.length > 0 ? item.items.map((obj, index) => (
                                                        index === item.items.length - 1 ? (
                                                            <span key={index}>{obj.item}</span>
                                                        ) : (
                                                            <span key={index}>{`${obj.item}, `}</span>
                                                        )
                                                    )

                                                    ) : 'No Items'
                                                }
                                            </Typography>

                                        </Paper>
                                    )) : <Grid item xs={12} sm={12} md={12} p={2}>
                                        <Typography variant="h4" display="block" gutterBottom>
                                            No data found
                                        </Typography>
                                    </Grid>}
                                    <div style={{ textAlign: 'center' }}>
                                        {error ? '' : <Button varient="container" color="primary" onClick={handleLoadMore}>Load More</Button>}
                                    </div>
                                </Card>

                            </div>
                        }

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HelpVideo title="Whitelist" />
                    </Grid>

                </Grid>
            </Container>
            <Popup title="Login Whishlist Bot" openPopup={open} setOpenPopup={setOpenPopup}>
                <WhishlistLogin popup={open} prevState={dataShow} setdataShow={setdataShow} configData={configData} setConfigData={setConfigData} accessToken={accessToken} popupChange={handleClickClose} />
            </Popup>
        </Page>

    );
}
