import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik, Form, FormikProvider } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Stack,
  TextField,
  Button, Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import account from '../../_mocks_/account';
// ----------------------------------------------------------------------

export default function TelegramAdd(props) {
  const [btnLoad, setbtnLoad] = useState(false);
  const [value, setValue] = useState('');
  const [userData, setUserData] = useState([]);


  useEffect(() => {
    if (account.pass_key === 1) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setUserData(data)
        });
    }
  }, []);


  const LoginSchema = Yup.object().shape({
    token: Yup.string().required('Token is required'),
    channel: Yup.string().required('Channel is required'),
  });
  const formik = useFormik({
    initialValues: (account.pass_key === 1 && value !== null) ? {
      user: value._id,
      token: '',
      channel: ''
    } : {
      token: '',
      channel: ''
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (initialValues) => {
      setbtnLoad(true)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.accessToken}`
        },
        body: JSON.stringify(initialValues),
      };
      fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/telegram", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setbtnLoad(false)
          if (Object.prototype.hasOwnProperty.call(data, "_id")) {
            setbtnLoad(false)
            props.setdataShow([...props.prevState, data]);
            props.popupChange(false);
            toast.success('Saved Sucessfully');
          } else {
            setbtnLoad(false)
            toast.error(data.message);
          }
        });
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const closePopup = () => {
    props.popupChange(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {account.pass_key === 1 &&
          <Autocomplete mt={2}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            id="controllable-states-demo"
            options={userData}
            getOptionLabel={(option) => option.username || ""}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.username}
              </Box>
            )}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} variant="standard" label="User Name" />}
          />}

        <Stack>
          <TextField sx={{ mt: "10px" }}
            fullWidth
            autoComplete="channel"
            type="text"
            variant="standard"
            label="Channel Name"
            {...getFieldProps('channel')}
            error={Boolean(touched.channel && errors.channel)}
            helperText={touched.channel && errors.channel}
          />
          <TextField sx={{ mt: "10px" }}
            fullWidth
            autoComplete="token"
            type="text"
            variant="standard"
            label="Token No."
            {...getFieldProps('token')}
            error={Boolean(touched.token && errors.token)}
            helperText={touched.token && errors.token}
          />
        </Stack>
        <Stack direction="row" sx={{ float: 'right', marginTop: '15px' }}>
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={closePopup}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="text"
            loading={btnLoad}
          >
            Save
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
