import PropTypes from 'prop-types';
import useRazorpay from "react-razorpay";
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@material-ui/core/styles';
import { Card, Button, Typography, Box, Stack } from '@mui/material';
import * as Icons from '@mui/icons-material';
import Label from '../../components/Label';
import account from '../../_mocks_/account';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

PricingAddon.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object
};


export default function PricingAddon({ card, userPlan, index }) {
  const navigate = useNavigate();
  const { amount, bitly, blogger, color, facebook, forwarder, name,
    telegram,
    twitter,
    whatsapp, textColor, buttonColor, imageUrl, popular,
    wordpress,
    _id } = card;
  const Razorpay = useRazorpay();

  const handlePayment = async (amount, id) => {
    // const order = await createOrder(params);

    const options = {
      key: "rzp_test_RCeR2PruJXzV7r", // Enter the Key ID generated from the Dashboard
      amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      // order_id: "order_9A33XWu170gUtm", 
      handler: (response) => {
        console.log(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
        const bundle = localStorage.getItem("aat");
        if (bundle != null && response.razorpay_payment_id) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${bundle}`
            },
            body: JSON.stringify({id,payment_id:response.razorpay_payment_id})
          };
          fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/plans", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                toast.success('Plan Activated Successfully');
                window.location.reload();
              } else {
                toast.error(data.message);
              }
            }).catch(e=>{
              toast.error(e.message);
            })
        } else {
          toast.error('No Token Found');
          navigate('/login', { replace: true });
        }

      },
      prefill: {
        name: account.displayName,
        email: account.email,
        contact: account.mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", (response) => {
      toast.error('Payment Failed')
      console.log(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();


  };

  return (
    <RootStyle sx={{backgroundColor: color}} key={index}>
    {popular && (
      <Label
      sx={{
        top: 16,
        right: 16,
        position: 'absolute',
        padding:'10px',
        color:textColor,
        backgroundColor:buttonColor
      }}
    >
      POPULAR
    </Label>
    )}

    <Typography variant="overline" sx={{ color: textColor }}>
      {name}
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Typography variant="subtitle1" sx={{ color: textColor }}>
          ₹
        </Typography>
      <Typography variant="h2" sx={{ mx: 1, color: textColor }}>
        {amount === 0 ? 'Free' : amount}
      </Typography>
        <Typography
          gutterBottom
          component="span"
          variant="subtitle2"
          sx={{
            alignSelf: 'flex-end',
            color: textColor
          }}
        >
          /mo
        </Typography>
    </Box>

    <Typography
      variant="caption"
      sx={{
        color: textColor,
        textTransform: 'capitalize'
      }}
    >
      {name}
    </Typography>

    <Box sx={{ width: 80, height: 80,mt:1,color:textColor,fontSize: 80 }}>{imageUrl ? <img src={imageUrl} alt="" />:<Icons.Casino />}</Box>

    <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: whatsapp !== 0 ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={whatsapp !== 0 ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">{whatsapp !== 0 ? whatsapp : ''} WhatsApp Access</Typography>
        </Stack>
        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: telegram !== 0 ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={telegram !== 0 ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">{telegram !== 0 ? telegram : ''} Telegram Access</Typography>
        </Stack>
        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: bitly !== 0 ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={bitly !== 0 ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">{bitly !== 0 ? bitly : ''} Bitly Access</Typography>
        </Stack>
        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: twitter !== 0 ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={twitter !== 0 ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">{twitter !== 0 ? twitter : ''} Twitter Access</Typography>
        </Stack>

        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: wordpress === true ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={wordpress === true ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">Wordpress</Typography>
        </Stack>

        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: facebook === true ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={facebook === true ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">Facebook</Typography>
        </Stack>
        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: forwarder === true ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={forwarder === true ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">Forwarder</Typography>
        </Stack>
        <Stack component="li" direction="row" alignItems="center" spacing={1.5}
          sx={{ typography: 'body2', color: blogger === true ? textColor : 'text.disabled' }}
        >
          <Box component={Icon} icon={blogger === true ? checkmarkFill : closeFill} sx={{ width: 20, height: 20 }} />
          <Typography variant="body2">Blogger</Typography>
        </Stack>
    </Stack>

    {(userPlan.addon !== '' && userPlan.addon.plan) ?<> {userPlan.addon.plan._id === _id? <Button
            fullWidth
            size="large"
            variant="contained"
            disabled
            sx={{
                textTransform: 'capitalize'
              }}
          > Current Addon
          </Button>: <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
                color: 'white',
                backgroundColor: buttonColor,
                textTransform: 'capitalize'
              }}
              onClick={() => handlePayment(amount, _id)}
          > Get Addon
          </Button>
        }</>: <Button
      fullWidth
      size="large"
      variant="contained"
      sx={{
          color: 'white',
          backgroundColor: buttonColor,
          textTransform: 'capitalize'
        }}
        onClick={() => handlePayment(amount, _id)}
    > Get Addon
    </Button>
    }
    
</RootStyle>
  );
}
