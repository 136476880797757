import { useState, useEffect } from 'react';
// import swal from 'sweetalert';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AccordionDetails, Grid, Accordion, AccordionSummary, Card, Button, Container, Stack, Typography, Paper, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { styled } from '@mui/material/styles';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import Page from '../../../components/Page';
import Popup from '../../../components/Popup';
import UsersEdit from './UsersEdit';
import UsersSearch from './UsersSearch';
import 'react-toastify/dist/ReactToastify.css'
// import Label from '../../../components/Label';
import LoadingScreen from '../../../components/LoadingScreen';


// ----------------------------------------------------------------------

export default function Users() {
	const navigate = useNavigate();
	const [openEdit, setOpenEdit] = useState(false);
	// const [open, setOpen] = useState(false);
	const [dataShow, setdataShow] = useState([]);
	const [id, setID] = useState(null);
	const [accessToken, setAccessToken] = useState();
	const [totalData, setTotalData] = useState([]);
	const [limit, setLimit] = useState(15);
	const [error, setError] = useState(false);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		const bundle = localStorage.getItem("aat");
		setLoading(true);
		if (bundle != null) {
			setAccessToken(bundle);
		} else {
			toast.error('No Token Found');
			navigate('/login', { replace: true });
		}
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${bundle}`
			},
		};
		fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				// console.log(data);
				if (data.length !== undefined) {
					setdataShow(data);
					setTotalData(data);
					setLoading(false);
					if (limit >= dataShow.length) {
						setError(true);
					}
				} else {
					toast.error(data.message);
				}
			});
	}, []);
	// -----------Add Twitter Popup-----------------
	// const handleClickOpen = () => {
	// 	setOpen((add) => !add);
	// }
	// const handleClickClose = (e) => {
	// 	setOpen((add) => !add);
	// }
	// const setOpenPopup = () => {
	// 	setOpen((add) => !add);
	// };
	// -----------Edit Twitter Popup-----------------
	const handleEditOpen = (id) => {
		setOpenEdit((edit) => !edit);
		setID(id);
	}
	const handleEditClose = () => {
		setOpenEdit((edit) => !edit);
	}
	const setEditPopup = () => {
		setOpenEdit((edit) => !edit);
	};
	// -----------Delete Twitter -----------------
	const handleDelete = (id) => {
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${accessToken}`
			},
		};
		fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users/${id}`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				if (Object.prototype.hasOwnProperty.call(data, "success")) {
					toast.error('Deleted Sucessfully');
					const newArray = dataShow.filter((item) => (
						item._id !== id
					));
					setdataShow(newArray);
				} else {
					toast.error(data.message);
				}
			});
	}
	// const handleLogoutAll = () => {
	// 	swal({
	// 		title: "Are you sure?",
	// 		text: "You want to logout all users?",
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	})
	// 		.then((willDelete) => {
	// 			if (willDelete) {
	// 				const requestOptions = {
	// 				    method: "POST",
	// 				    headers: {
	// 				        "Authorization": `Bearer ${accessToken}`
	// 				    },
	// 				};
	// 				fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/users/logoutAll", requestOptions)
	// 				    .then((response) => response.json())
	// 				    .then((data) => {
	// 				        console.log(data);
	// 				        // toast.error("Delete Successfully");
	// 				        // window.location.reload();
	// 				    });
	// 				swal("Poof! Your imaginary file has been deleted!", {
	// 					icon: "success",
	// 				});
	// 			} else {
	// 				swal("Your abort thi operation!");
	// 			}
	// 		});
	// }


	const handleLoadMore = () => {
		if (limit < dataShow.length) {
			setLimit(limit + 5);
			const l = limit + 5;
			if (l >= dataShow.length) {
				setError(true);
			}
		} else {
			setError(true);
		}

	}
	return (
		<Page title="Users">
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>
						Users
					</Typography>
					{/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
						<Button
							variant="contained"
							component={RouterLink}
							to="#"
							color="primary"
							// startIcon={<Icon icon={plusFill} />}
							onClick={handleLogoutAll}
						>
							Logout All
						</Button>
					</ButtonGroup> */}
				</Stack>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Card sx={{ p: 0, mb: 3 }}>
							<Accordion sx={{ p: 0, m: 0 }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography variant="h5" gutterBottom>
										Filters
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
										<UsersSearch prevState={dataShow} totalData={totalData} setdataShow={setdataShow} setlimit={setLimit} seterror={setError} />
									</Stack>
								</AccordionDetails>
							</Accordion>
						</Card>
						{isLoading ? <LoadingScreen sx={{
							top: 40,
							left: 0,
							width: 1,
							zIndex: 9999,
							position: 'fixed'
						}} /> :
							<Card sx={{ p: 3 }}>
								{(dataShow.length > 0 && dataShow !== undefined) ? dataShow.slice(0, limit).map((item) => (
									<Paper
										key={item._id}
										sx={{
											p: 3,
											width: 1,
											marginBottom: 1,
											bgcolor: 'background.neutral'
										}}
									>
										<Grid container>
											<Grid item xs={12} md={6} lg={6}>
												<Typography variant="body2" gutterBottom >
													<Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
														Username: &nbsp;
													</Typography>
													{item.username}
												</Typography>
											</Grid>
											<Grid item xs={12} md={6} lg={6}>
												<Typography variant="body2" gutterBottom >
													<Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
														Email: &nbsp;
													</Typography>
													{item.email}
												</Typography>
											</Grid>
											<Grid item xs={12} md={6} lg={6}>
												<Typography variant="body2" gutterBottom >
													<Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
														Mobile: &nbsp;
													</Typography>
													{item.mobile}
												</Typography>
											</Grid>
											<Grid item xs={12} md={6} lg={6}>
												<Typography variant="body2" gutterBottom >
													<Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
														Admin: &nbsp;
													</Typography>
													{item.admin}
												</Typography>
											</Grid>
										</Grid>
										<Box sx={{ mt: 1 }}>
											<Button size="small" startIcon={<Icon icon={editFill} />} onClick={() => handleEditOpen(item)}>
												Edit
											</Button>
											<Button
												color="error"
												size="small"
												startIcon={<Icon icon={trash2Fill} />}
												onClick={() => handleDelete(item._id)}
												sx={{ mr: 1 }}
											>
												Delete
											</Button>
										</Box>
									</Paper>
								)) : <Grid item xs={12} sm={12} md={12} >
									<Typography variant="h" gutterBottom>
										No data found
									</Typography>
								</Grid>}
								<div style={{ textAlign: 'center' }}>
									{error ? '' : <Button varient="container" color="primary" onClick={handleLoadMore}>Load More</Button>}
								</div>
							</Card>}

					</Grid>

				</Grid>

			</Container>
			{/* <Popup title="Add Twitter" openPopup={open} setOpenPopup={setOpenPopup}>
				<TwitterAdd popup={open} prevState={dataShow} setdataShow={setdataShow} id={id} accessToken={accessToken} popupChange={handleClickClose} />
			</Popup> */}
			<Popup title="Edit Twitter" openPopup={openEdit} setOpenPopup={setEditPopup}>
				<UsersEdit popup={openEdit} prevState={dataShow} setdataShow={setdataShow} id={id} accessToken={accessToken} popupChange={handleEditClose} />
			</Popup>
		</Page>
	);
}
