// import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function BitlyEdit(props) {
    // console.log(props)
    // const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    const [dataShow, setDataShow] = useState({
        phone: '',
        url: '',
        token: ''
    })
    useEffect(() => {
        setDataShow({ token: props.id.token, phone: props.id.phone, url: props.id.url });
    }, [props.id]);

    console.log(dataShow)

    const formik = useFormik({
        initialValues: dataShow,
        enableReinitialize: true,
        onSubmit: (initialValues) => {
            console.log("initialValue", initialValues);
            setbtnLoad(true)
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/forwarder/setup/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        toast.success('Updated Sucessfully')
                        setbtnLoad(false)
                        props.setdataShow(props.prevState.map(item => (
                            item._id === props.id._id ? { ...data } : item
                        )))
                        props.popupChange(false);
                    } else {
                        setbtnLoad(false)
                        toast.error(data.message)
                    }
                });
        }
    });

    const { values, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="phone"
                        type="text"
                        value={values.phone}
                        variant="standard"
                        label="Phone"
                        {...getFieldProps('phone')}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="token"
                        type="text"
                        value={values.token}
                        variant="standard"
                        label="Token No."
                        {...getFieldProps('token')}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="url"
                        type="text"
                        value={values.url}
                        variant="standard"
                        label="URL"
                        {...getFieldProps('url')}
                    />
                </Stack>
                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
