// url
// affiliater user name

import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import account from '../../../_mocks_/account';

export default function ForwarderSearch(props) {
    // console.log(props)
    const [user, setUser] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [btnLoad, setbtnLoad] = React.useState(false);
    const [btnResetLoad, setResetLoad] = React.useState(false);
    const arr = [];
    props.totalData.forEach((arrayItem) => (
        arr.push(arrayItem.user.username)
    ));
    const newArr = [...new Set(arr)];

    const urlArr = [];
    props.totalData.forEach((arrayItem) => (
        urlArr.push(arrayItem.url)
    ));
    const newUrlArr = [...new Set(urlArr)];


    const allData = props.totalData;
    let filterdata = [];
    const handleResetClick = () => {
        setResetLoad(true)
        props.setdataShow(allData)
        setUser('');
        setUrl('');
        if (allData.length > 15) {
            props.setlimit(5)
            props.seterror(false)
        } else if (allData.length <= 15) {
            props.setlimit(5)
            props.seterror(true)
        } else {
            props.setlimit(0)
            props.seterror(true)
        }
        setResetLoad(false)
    }
    const handleClick = () => {
        setbtnLoad(true)
        // user search-------------------------------------------------
        if (user) {
            const result = allData.filter((item) => (
                item.user.username === user
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        } else {
            filterdata = (filterdata.length === 0) ? allData : filterdata
        }
        // token search 
        
        if (url && filterdata) {
            const result = filterdata.filter((item) => (
                item.url === url
            ));
            if (result.length > 0) {
                filterdata = result
            } else {
                filterdata = ''
            }
        } 
    
        props.setdataShow(filterdata)
        if (filterdata.length > 15) {
            props.setlimit(5)
            props.seterror(false)
        } else if (filterdata.length <= 15) {
            props.setlimit(5)
            props.seterror(true)
        } else {
            props.setlimit(0)
            props.seterror(true)
        }
        setbtnLoad(false)
    }
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} md={6}> <Autocomplete
                    value={user}
                    onChange={(event, newValue) => {
                        setUser(newValue);
                    }}
                    id="controllable-states-demo"
                    options={newArr}
                    getOptionLabel={(option) => option || ""}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} variant="standard" label="UserName Search" />}
                /> </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={url}
                        onChange={(event, newValue) => {
                            setUrl(newValue);
                        }}
                        id="controllable-states-demo"
                        options={newUrlArr}
                        getOptionLabel={(option) => option || ""}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Url Search" />}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                        onClick={handleClick}
                    >
                        Search
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        color="error"
                        loading={btnResetLoad}
                        onClick={handleResetClick}
                    >
                        Reset
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}
