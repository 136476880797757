import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// material
import {
    Stack,
    TextField,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function TwitterEdit(props) {
    // console.log(props.id)
    // const navigate = useNavigate();
    const [btnLoad, setbtnLoad] = useState(false);
    const [twitter, setTwitter] = useState({
        api_key: '',
        api_secret: '',
        access_token: '',
        token_secret: ''
    })
    useEffect(() => {
        setTwitter({
            api_key: props.id.api_key,
            api_secret: props.id.api_secret,
            access_token: props.id.access_token,
            token_secret: props.id.token_secret
        });
    }, [props.id]);

    const LoginSchema = Yup.object().shape({
        api_key: Yup.string().required('API Key is required'),
        api_secret: Yup.string().required('API Secret is required'),
        access_token: Yup.string().required('Access Token is required'),
        token_secret: Yup.string().required('Token Secret is required'),
    });
    // prop.id Get token call API 

    const formik = useFormik({
        initialValues: twitter,
        enableReinitialize: true,
        validationSchema: LoginSchema,
        onSubmit: (initialValues) => {
            // console.log("initialvalues",initialValues);
            setbtnLoad(true)
            const requestOptions = {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.accessToken}`
                },
                body: JSON.stringify(initialValues)
            };
            // console.log(requestOptions);
            fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/twitter/${props.id._id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                        toast.success('Updated Sucessfully')
                        setbtnLoad(false)
                        props.setdataShow(props.prevState.map(item => (
                            item._id === props.id._id ? { ...data } : item
                        )))
                        props.popupChange(false);
                    } else {
                        setbtnLoad(false)
                        toast.error(data.message)
                    }
                });
            // navigate('/dashboard', { replace: true });
        }
    });

    const { errors, touched, values, handleSubmit, getFieldProps } = formik;

    const closePopup = () => {
        props.popupChange(false);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="api_key"
                        type="text"
                        value={values.api_key}
                        variant="standard"
                        label="API Key"
                        {...getFieldProps('api_key')}
                        error={Boolean(touched.api_key && errors.api_key)}
                        helperText={touched.api_key && errors.api_key}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="api_secret"
                        type="text"
                        value={values.api_secret}
                        variant="standard"
                        label="API Secret"
                        {...getFieldProps('api_secret')}
                        error={Boolean(touched.api_secret && errors.api_secret)}
                        helperText={touched.api_secret && errors.api_secret}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="access_token"
                        type="text"
                        value={values.access_token}
                        variant="standard"
                        label="Access Token"
                        {...getFieldProps('access_token')}
                        error={Boolean(touched.access_token && errors.access_token)}
                        helperText={touched.access_token && errors.access_token}
                    />
                </Stack>
                <Stack mt={2}>
                    <TextField
                        fullWidth
                        autoComplete="token_secret"
                        type="text"
                        value={values.token_secret}
                        variant="standard"
                        label="Token Secret"
                        {...getFieldProps('token_secret')}
                        error={Boolean(touched.token_secret && errors.token_secret)}
                        helperText={touched.token_secret && errors.token_secret}
                    />
                </Stack>
                <Stack direction="row" sx={{ float: 'right', marginTop: '5px' }}>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="text"
                        loading={btnLoad}
                    >
                        Save
                    </LoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    );
}
