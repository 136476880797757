import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Switch, Grid, FormControlLabel, Card, Button, Container, Stack, Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// import editFill from '@iconify/icons-eva/edit-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import Page from '../../components/Page';
// import Popup from '../../components/Popup';
// import TwitterEdit from './TwitterEdit';
import WhatsAppLogin from './WhatsAppLogin';
// import TwitterSearch from './TwitterSearch';
import 'react-toastify/dist/ReactToastify.css';
import { HelpVideo } from '../../components/authentication/help';
import Label from '../../components/Label';
import LoadingScreen from '../../components/LoadingScreen';


// ----------------------------------------------------------------------
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: '#d50000',
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        // color: '#fafafa',
        // borderRadius: '2px',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// ----------------------------------------------------------------------

export default function Twitter() {
    const navigate = useNavigate();
    // const [openEdit, setOpenEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [dataShow, setdataShow] = useState([]);
    // const [id, setID] = useState(null);
    const [accessToken, setAccessToken] = useState();
    // const [totalData, setTotalData] = useState([]);
    const [limit, setLimit] = useState(15);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // const [qrState, setQrState] = useState({});

    useEffect(() => {
        const bundle = localStorage.getItem("aat");
        setLoading(true);
        if (bundle != null) {
            setAccessToken(bundle);
        } else {
            toast.error('No Token Found');
            navigate('/login', { replace: true });
        }
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bundle}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.length !== undefined) {
                    setdataShow(data);
                    // setTotalData(data);
                    setLoading(false);
                    if (limit >= dataShow.length) {
                        setError(true);
                    }
                } else {
                    toast.error(data.message);
                }
            });
    }, []);



    // -----------Add Twitter Popup-----------------
    const handleLogin = () => {
        setOpen((add) => !add);
    }
    const handleClickClose = () => {
        setOpen((add) => !add);
    }
    // const setOpenPopup = () => {
    //     setOpen((add) => !add);
    // };
    // -----------Edit Twitter Popup-----------------
    // const handleEditOpen = (id) => {
    // 	setOpenEdit((edit) => !edit);
    // 	setID(id);
    // }
    // const handleEditClose = (e) => {
    // 	setOpenEdit((edit) => !edit);
    // }
    // const setEditPopup = () => {
    // 	setOpenEdit((edit) => !edit);
    // };
    // -----------Delete Twitter -----------------
    const handleDelete = (id) => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        };
        fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "success")) {
                    toast.error('Deleted Sucessfully');
                    const newArray = dataShow.filter((item) => (
                        item._id !== id
                    ));
                    setdataShow(newArray);
                } else {
                    toast.error(data.message);
                }
            });
    }

    const handleDeleteAll = () => {
        swal({
            title: "Are you sure?",
            text: "You want to delete all groups?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const requestOptions = {
                        method: "GET",
                        headers: {
                            "Authorization": `Bearer ${accessToken}`
                        },
                    };
                    fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp/delete-all", requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            console.log(data);
                            toast.error("Delete Successfully");
                            window.location.reload();
                        });
                    // swal("Poof! Your imaginary file has been deleted!", {
                    //     icon: "success",
                    // });
                } else {
                    swal("Your abort thi operation!");
                }
            });
    }

    const handleFindGroups = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
        };
        fetch("https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp/find-groups", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (Object.prototype.hasOwnProperty.call(data, "success")) {
                    toast.success(data.message);
                    window.location.reload();
                } else {
                    toast.error(data.message);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleRadioChange = (e, id) => {
        const requestOptions = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ "status": e.target.checked })
        };
        fetch(`https://gi8b45dvhc.execute-api.us-east-1.amazonaws.com/dev/whatsapp/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, "_id")) {
                    setdataShow(dataShow.map(item => (
                        item._id === id ? { ...data } : item
                    )))
                } else {
                    toast.error(data.message);
                }
            });
    }
    const handleLoadMore = () => {
        if (limit < dataShow.length) {
            setLimit(limit + 5);
            const l = limit + 5;
            if (l >= dataShow.length) {
                setError(true);
            }
        } else {
            setError(true);
        }

    }
    return (
        <Page title="WhatsApp">
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Grid container >
                        <Grid item xs={12} sm={6} md={8}>
                            <Typography variant="h4" gutterBottom>
                                WhatsApp
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} justifyContent="center">
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to="#"
                                    color="primary"
                                    // startIcon={<Icon icon={plusFill} />}
                                    onClick={handleLogin}
                                >
                                    Login
                                </Button>
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to="#"
                                    color="warning"
                                    // startIcon={<Icon icon={plusFill} />}
                                    onClick={handleFindGroups}
                                >
                                    Find Groups
                                </Button>
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to="#"
                                    color="error"
                                    // startIcon={<Icon icon={plusFill} />}
                                    onClick={handleDeleteAll}
                                >
                                    Delete All
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>

                        {isLoading ? <LoadingScreen sx={{
                            top: 40,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed'
                        }} /> :
                            <Card sx={{ p: 3 }}>
                                {(dataShow.length > 0 && dataShow !== undefined) ? dataShow.slice(0, limit).map((item) => (
                                    <Paper
                                        key={item._id}
                                        sx={{
                                            p: 3,
                                            width: 1,
                                            marginBottom: 1,
                                            bgcolor: 'background.neutral'
                                        }}
                                    >

                                        <Typography variant="body2" gutterBottom>
                                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                Name: &nbsp;
                                            </Typography>
                                            {item.name}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                                                Status: &nbsp;
                                            </Typography>
                                            <Label
                                                color={item.status === false ? 'error' : 'success'}
                                                sx={{ textTransform: 'uppercase' }}
                                            >
                                                {item.status === false ? 'Deactive' : 'Active'}
                                            </Label>
                                        </Typography>

                                        <Box sx={{ mt: 1 }}>
                                            <FormControlLabel
                                                control={<Android12Switch value={item.status} checked={item.status} onChange={(e) => handleRadioChange(e, item._id)} />}
                                                label=""
                                            />
                                            {/* <Button size="small" startIcon={<Icon icon={editFill} />} onClick={() => handleEditOpen(item)}>
												Edit
											</Button> */}
                                            <Button
                                                color="error"
                                                size="small"
                                                startIcon={<Icon icon={trash2Fill} />}
                                                onClick={() => handleDelete(item._id)}
                                                sx={{ mr: 1 }}
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    </Paper>
                                )) : <Grid item xs={12} sm={12} md={12} >
                                    <Typography variant="h" gutterBottom>
                                        No data found
                                    </Typography>
                                </Grid>}
                                <div style={{ textAlign: 'center' }}>
                                    {error ? '' : <Button varient="container" color="primary" onClick={handleLoadMore}>Load More</Button>}
                                </div>
                            </Card>}

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HelpVideo title="WhatsApp" />
                    </Grid>

                </Grid>

            </Container>

            <WhatsAppLogin popup={open} accessToken={accessToken} popupChange={handleClickClose} />

            {/* <Popup title="Edit Twitter" openPopup={openEdit} setOpenPopup={setEditPopup}>
				<TwitterEdit popup={openEdit} prevState={dataShow} setdataShow={setdataShow} id={id} accessToken={accessToken} popupChange={handleEditClose} />
			</Popup> */}
        </Page>
    );
}
