import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// import editFIll from '@iconify/icons-eva/edit-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {  Button,TextField, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
//
// import POSTS from '../_mocks_/blog';

// ----------------------------------------------------------------------

// const SORT_OPTIONS = [
//   { value: 'latest', label: 'Latest' },
//   { value: 'popular', label: 'Popular' },
//   { value: 'oldest', label: 'Oldest' }
// ];

// ----------------------------------------------------------------------

export default function Converter() {
  return (
    <Page title="Converter | Affiliaters">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Converter
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New Post
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <TextField
            id="outlined-multiline-flexible"
            multiline
            minRows={6}
            sx={{ width: '100%' }}
            />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button variant="contained" startIcon={<Icon icon="fad:random-2dice" width="50"/> } sx={{ width: '100%' }}>
                Convert
            </Button>
        </Stack>
      </Container>
    </Page>
  );
}
